import { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeftIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardsGridPagination from './DashboardsGridPagination';
import Button from './Button';
import TextInputHeading from './TextInputHeading';
import DashboardPagesList from './DashboardPagesList';
import DashboardCanvas from './DashboardCanvas';

export default function DashboardContainer({ mode, dashboard, pageIndex, onDashboardAction, accessTypes, version, pdfOrientation }) {

    const [pageSelectExpanded, setPageSelectExpanded] = useState(true);
    const [title, setTitle] = useState(dashboard.title);

    const canvasRef = useRef(null);

    const canvasArrayRef = useRef([]);
    
    const [canvasRenders, _setCanvasRenders] = useState([]);
    const canvasRendersRef = useRef(canvasRenders);
    const setCanvasRenders = data => {
        canvasRendersRef.current = data;
        _setCanvasRenders(data);
    };

    const [allExportTrigger, setAllExportTrigger] = useState(false);
    const [allExportDone, setAllExportDone] = useState(false);

    
    /* Export All Pages Function */

    const exportFullDashboardPortrait = useCallback(() => {
        let canvases = Array(canvasArrayRef.current.length).fill(null);
        let canvasPromises = [];
        canvasArrayRef.current.forEach((cref, index) => {
            canvasPromises.push(new Promise((resolve, reject) => {
                html2canvas(cref)
                .then(canvas => {
                    canvases[index] = canvas;
                    resolve();
                })
                .catch(err => reject(err));
            }));
        });
        Promise.all(canvasPromises)
        .then(() => {
            canvases = canvases.filter(cv => cv !== null);
            let a4pages = [];
            for (let c = 0; c < canvases.length; c += 2) {
                let a4p = {top: canvases[c], bottom: null };
                if (c + 1 < canvases.length) {
                    a4p.bottom = canvases[c + 1];
                }
                a4pages.push(a4p);
            }
            const pdf = new jsPDF({
                orientation: "p", 
                unit: "mm", 
                format: "a4", 
                putOnlyUsedFonts: true
            });
            const pdfWidth = pdf.internal.pageSize.getWidth() - (DashboardConstants.PDF_MARGIN * 2);
            const pdfHeight = pdf.internal.pageSize.getHeight() - (DashboardConstants.PDF_MARGIN * 2);
            for (let a = 0; a < a4pages.length; a++) {
                if (a > 0) {
                    pdf.addPage();
                    pdf.setPage(a + 1);
                }
                const topCanvas = a4pages[a].top;
                const topImgData = topCanvas.toDataURL("image/png");
                const topImgWidth = topCanvas.width;
                const topImgHeight = topCanvas.height;
                const topRatio = Math.min(pdfWidth / topImgWidth, pdfHeight / topImgHeight);
                const topImgX = ((pdfWidth - topImgWidth * topRatio) / 2) + DashboardConstants.PDF_MARGIN;
                const topImgY = DashboardConstants.PDF_MARGIN;
                pdf.addImage(
                    topImgData,
                    "PNG",
                    topImgX,
                    topImgY,
                    topImgWidth * topRatio,
                    topImgHeight * topRatio
                );
                if (a4pages[a].bottom) {
                    const bottomCanvas = a4pages[a].bottom;
                    const bottomImgData = bottomCanvas.toDataURL("image/png");
                    const bottomImgWidth = bottomCanvas.width;
                    const bottomImgHeight = bottomCanvas.height;
                    const bottomRatio = Math.min(pdfWidth / bottomImgWidth, pdfHeight / bottomImgHeight);
                    const bottomImgX = ((pdfWidth - bottomImgWidth * bottomRatio) / 2) + DashboardConstants.PDF_MARGIN;
                    const bottomImgY = pdfHeight - (topImgHeight * bottomRatio) - DashboardConstants.PDF_MARGIN;
                    pdf.addImage(
                        bottomImgData,
                        "PNG",
                        bottomImgX,
                        bottomImgY,
                        bottomImgWidth * bottomRatio,
                        bottomImgHeight * bottomRatio
                    );
                }            
            }
            let filename = `${dashboard.title}.pdf`;
            filename = filename.replaceAll(" ", "_").replace("#", "No_");
            pdf.save(filename);
            if (onDashboardAction) {
                alert("Full dashboard PDF exported successfully");
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        })
        .catch(err => {
            console.log(err);
            alert("Unable to export dashboard to PDF. Please try again later.");
            if (onDashboardAction) {
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        });
    }, [dashboard, onDashboardAction]);

    const exportFullDashboardLandscape = useCallback(() => {
        let canvases = Array(canvasArrayRef.current.length).fill(null);
        let canvasPromises = [];
        canvasArrayRef.current.forEach((cref, index) => {
            canvasPromises.push(new Promise((resolve, reject) => {
                html2canvas(cref)
                .then(canvas => {
                    canvases[index] = canvas;
                    resolve();
                })
                .catch(err => reject(err));
            }));
        });
        Promise.all(canvasPromises)
        .then(() => {
            canvases = canvases.filter(cv => cv !== null);
            const pdf = new jsPDF({
                orientation: "l", 
                unit: "mm", 
                format: "a4", 
                putOnlyUsedFonts: true
            });
            const pdfWidth = pdf.internal.pageSize.getWidth() - (DashboardConstants.PDF_MARGIN * 2);
            const pdfHeight = pdf.internal.pageSize.getHeight() - (DashboardConstants.PDF_MARGIN * 2);
            for (let c = 0; c < canvases.length; c++) {
                if (c > 0) {
                    pdf.addPage();
                    pdf.setPage(c + 1);
                }
                const pageCanvas = canvases[c];
                const imgData = pageCanvas.toDataURL("image/png");
                const imgWidth = pageCanvas.width;
                const imgHeight = pageCanvas.height;
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                const imgX = ((pdfWidth - imgWidth * ratio) / 2) + DashboardConstants.PDF_MARGIN;
                const imgY = ((pdfHeight - imgHeight * ratio) / 2) + DashboardConstants.PDF_MARGIN;
                pdf.addImage(
                    imgData,
                    "PNG",
                    imgX,
                    imgY,
                    imgWidth * ratio,
                    imgHeight * ratio
                );
            }
            let filename = `${dashboard.title}.pdf`;
            filename = filename.replaceAll(" ", "_").replace("#", "No_");
            pdf.save(filename);
            if (onDashboardAction) {
                alert("Full dashboard PDF exported successfully");
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        })
        .catch(err => {
            console.log(err);
            alert("Unable to export dashboard to PDF. Please try again later.");
            if (onDashboardAction) {
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        });
    }, [canvasArrayRef, dashboard, onDashboardAction]);

    const exportSinglePage = useCallback(() => {
        html2canvas(canvasRef.current)
        .then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "l", 
                unit: "mm", 
                format: "a4", 
                putOnlyUsedFonts: true
            });
            const pdfWidth = pdf.internal.pageSize.getWidth() - (DashboardConstants.PDF_MARGIN * 2);
            const pdfHeight = pdf.internal.pageSize.getHeight() - (DashboardConstants.PDF_MARGIN * 2);
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = ((pdfWidth - imgWidth * ratio) / 2) + DashboardConstants.PDF_MARGIN;
            const imgY = ((pdfHeight - imgHeight * ratio) / 2) + DashboardConstants.PDF_MARGIN;
            pdf.addImage(
                imgData,
                "PNG",
                imgX,
                imgY,
                imgWidth * ratio,
                imgHeight * ratio
            );
            let filename = `${dashboard.title}-page-${pageIndex + 1}.pdf`;
            filename = filename.replaceAll(" ", "_").replace("#", "No_");
            pdf.save(filename);
            if (onDashboardAction) {
                alert("Dashboard page PDF exported successfully");
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        })
        .catch(err => {
            console.log(err);
            alert("Unable to export page to PDF. Please try again later.");
            if (onDashboardAction) {
                onDashboardAction({ 
                    action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                    args: { mode: DashboardConstants.CanvasMode.VIEW }
                });
            }
        });
    }, [canvasRef, dashboard, pageIndex, onDashboardAction]);

    const updateCanvasRenders = (details) => {
        const page = details.args.page;
        const success = details.args.success;
        if (canvasRendersRef.current.map(cr => cr.index).indexOf(page) === -1) {
            let newCanvasRenders = JSON.parse(JSON.stringify(canvasRendersRef.current));
            newCanvasRenders.push({ index: page, success: success });
            setCanvasRenders(newCanvasRenders);
            /*
            if (newCanvasRenders.length >= dashboard.pages.length) {
                switch (pdfOrientation) {
                    case DashboardConstants.PageOrientation.PORTRAIT:
                        exportFullDashboardPortrait();
                        break;
                    case DashboardConstants.PageOrientation.LANDSCAPE:
                        exportFullDashboardLandscape();
                        break;
                    default:
                        break;
                }
            }
            */
        }
    }

    /* Interaction Functions */

    const onBackClick = () => {
        if (onDashboardAction) {
            onDashboardAction({ action: DashboardConstants.ActionType.DESELECT_DASHBOARD });
        }
    }
    const onExpandedToggle = () => {
        setPageSelectExpanded(!pageSelectExpanded);
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const onTitleBlur = () => {
        if (onDashboardAction) {
            onDashboardAction({
                action: DashboardConstants.ActionType.UPDATE_DASHBOARD_TITLE,
                args: { title: title }
            });
        }
    }

    const onExportClick = (e) => {
        e.preventDefault();
        if (onDashboardAction) {
            onDashboardAction({ action: DashboardConstants.ActionType.EXPORT_MODAL_OPEN });
        }
    }

    const onEditClick = (e) => {
        e.preventDefault();
        if (accessTypes.edit) {
            if (onDashboardAction) {
                if (mode === DashboardConstants.CanvasMode.EDIT) {
                    onDashboardAction({ 
                        action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                        args: { mode: DashboardConstants.CanvasMode.VIEW }
                    });
                } else {
                    onDashboardAction({ 
                        action: DashboardConstants.ActionType.SET_CANVAS_MODE, 
                        args: { mode: DashboardConstants.CanvasMode.EDIT }
                    });
                }
            }   
        }
    }

    const onPageChange = (index) => {
        if (onDashboardAction) {
            onDashboardAction({
                action: DashboardConstants.ActionType.SELECT_PAGE, 
                args: { index: index }
            });
        }
}

    const onPagesListAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.CREATE_PAGE:
                if (accessTypes.create) {
                    if (onDashboardAction) {
                        onDashboardAction({action: DashboardConstants.ActionType.CREATE_PAGE });
                    }
                }
                break;
            case DashboardConstants.ActionType.SELECT_PAGE:
                onPageChange(details.args.index);
                break;
            case DashboardConstants.ActionType.DELETE_PAGE:
                if (accessTypes.delete) {
                    if (onDashboardAction) {
                        onDashboardAction(details);
                    }
                }
                break;
            default:
                break;
        }
    }

    const onCanvasAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_CREATE:
                if (accessTypes.create) {
                    if (onDashboardAction) {
                        onDashboardAction({
                            action: DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_CREATE,
                            args: {
                                boardId: dashboard._id,
                                page: details.args.page,
                                row: details.args.row,
                                col: details.args.col 
                            }
                        });
                    }
                }
                break;
        
            case DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_EDIT:
                if (accessTypes.edit) {
                    if (onDashboardAction) {
                        onDashboardAction({
                            action: DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_EDIT,
                            args: {
                                boardId: dashboard._id,
                                id: details.args.id,
                                page: details.args.page,
                                row: details.args.row,
                                col: details.args.col,
                                width: details.args.width,
                                height: details.args.height 
                            }
                        });
                    }
                }
                break;
    
            case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
                if (accessTypes.delete) {
                    if (onDashboardAction) {
                        onDashboardAction({
                            action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                            args: {
                                boardId: dashboard._id,
                                id: details.args.id,
                                page: details.args.page,
                                row: details.args.row,
                                col: details.args.col 
                            }
                        });
                    }
                }
                break;

            case DashboardConstants.ActionType.TEXT_UPDATED:
                if (accessTypes.edit) {
                    if (onDashboardAction) {
                        onDashboardAction({
                            action: DashboardConstants.ActionType.TEXT_UPDATED,
                            args: {
                                boardId: dashboard._id,
                                id: details.args.id,
                                page: details.args.page,
                                text: details.args.text 
                            }
                        });
                    }
                }
                break;
        
            case DashboardConstants.ActionType.CANVAS_LOAD_COMPLETE:
                if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
                    updateCanvasRenders(details);
                }
                break;

            case DashboardConstants.ActionType.COLUMN_WIDTHS_UPDATED:
                if (onDashboardAction) {
                    onDashboardAction({
                        action: DashboardConstants.ActionType.COLUMN_WIDTHS_UPDATED,
                        args: {
                            boardId: dashboard._id,
                            id: details.args.id,
                            page: details.args.page,
                            columnWidths: details.args.columnWidths
                        }
                    });
                }
                break;

            default:
                break;
        }
    }

    /* Export Functions */

    useEffect(() => {
        let exportTrigger = allExportTrigger;
        if (mode === DashboardConstants.CanvasMode.EXPORT) {
            exportSinglePage();
        }
        if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
            if (!allExportDone && canvasRendersRef.current.length >= dashboard.pages.length) {
                exportTrigger = true;
                setAllExportTrigger(true);
            }
        } else {
            setAllExportDone(false);
        }
        if (exportTrigger) {
            switch (pdfOrientation) {
                case DashboardConstants.PageOrientation.PORTRAIT:
                    exportFullDashboardPortrait();
                    break;
                case DashboardConstants.PageOrientation.LANDSCAPE:
                    exportFullDashboardLandscape();
                    break;
                default:
                    break;
            }
            setAllExportTrigger(false);
            setCanvasRenders([]);
            setAllExportDone(true);
        }
    }, [
        mode, dashboard, pdfOrientation, 
        canvasRef, canvasRendersRef, allExportTrigger, allExportDone,
        exportSinglePage, exportFullDashboardPortrait, exportFullDashboardLandscape
    ]);

    return (
        <div className="flex flex-col items-stretch gap-6">
            <div className="flex items-center gap-1.5">
                <button onClick={onBackClick}>
                    <ChevronLeftIcon className="w-6 h-6 text-black"/>
                </button>
                {(mode === DashboardConstants.CanvasMode.EDIT && accessTypes.edit) ? (
                    <TextInputHeading 
                        textSize="text-[38px]" 
                        value={title}
                        onChange={onTitleChange}
                        onBlur={onTitleBlur}
                    />
                ) : (
                    <h6 className="font-vg-medium text-[38px] text-black">{dashboard.title}</h6>
                )}
            </div>
            <div className="flex flex-col items-stretch">
                <div className="flex flex-col items-center">
                    <button className="px-4 rounded-t-lg bg-grey04 border-t border-l border-r border-grey03 z-8" onClick={onExpandedToggle}>
                        {pageSelectExpanded ? (
                            <ChevronDownIcon className={`w-6 h-6 text-black`}/>
                        ) : (
                            <ChevronUpIcon className={`w-6 h-6 text-black`}/>
                        )}
                    </button>
                </div>
                <div className="flex flex-col gap-6 p-6 rounded-t-lg bg-grey04 border border-grey03 z-9 m-[-1px]">
                    {pageSelectExpanded ? (
                    <>
                        <div className="flex items-center justify-between">
                            <DashboardsGridPagination  
                                variant="full"
                                count={dashboard.pages.length}
                                pageSize={1}
                                page={pageIndex}
                                onPageChange={onPageChange}
                            />
                            <div className="flex justify-start items-center gap-3">
                                <Button
                                    variant="outline"
                                    size="large"
                                    label="Export"
                                    leftIcon="UploadIcon"
                                    onClick={onExportClick}
                                />
                                {accessTypes.edit && (
                                    <Button
                                        variant="solid"
                                        size="large"
                                        label={mode === DashboardConstants.CanvasMode.EDIT ? "Finish editing" : "Edit dashboard"}
                                        leftIcon="PencilIcon"
                                        onClick={onEditClick}
                                    />
                                )}
                            </div>
                        </div>
                        <DashboardPagesList
                            pages={dashboard.pages}
                            selectedPage={pageIndex}
                            onPagesListAction={onPagesListAction}
                        />
                    </>
                    ) : (
                    <div className="flex items-center justify-between">
                        <DashboardsGridPagination  
                            variant="minimal"
                            count={dashboard.pages.length}
                            pageSize={1}
                            page={pageIndex}
                            onPageChange={onPageChange}
                    />
                        <div className="flex justify-start items-center gap-3">
                            <Button
                                variant="outline"
                                size="large"
                                leftIcon="UploadIcon"
                                onClick={onExportClick}
                            />
                            {accessTypes.edit && (
                                <Button
                                    variant="solid"
                                    size="large"
                                    leftIcon="PencilIcon"
                                    onClick={onEditClick}
                                />
                            )}
                        </div>
                    </div> 
                    )}
                </div>
            </div>
            {mode === DashboardConstants.CanvasMode.EXPORT_ALL ? dashboard.pages.map((page, index) => (
                <DashboardCanvas
                    key={`canvas-${index}`}
                    ref={el => canvasArrayRef.current[index] = el}
                    dashboard={dashboard}
                    pageIndex={index}
                    mode={mode}
                    onCanvasAction={onCanvasAction}
                    version={version}
                />
            )) : (
                <DashboardCanvas
                    ref={canvasRef}
                    dashboard={dashboard}
                    pageIndex={pageIndex}
                    mode={mode}
                    onCanvasAction={onCanvasAction}
                    version={version}
                />
            )}
        </div>
    );
}