import '../styles/lexical-button.css';

import UiUtils from '../utils/UiUtils';

export default function LexicalButton({
   'data-test-id': dataTestId,
   children,
   className,
   onClick,
   disabled,
   small,
   title,
}) {
    return (
        <button
            disabled={disabled}
            className={UiUtils.classNames(
                'font-vg-medium text-base text-blue border border-blue h-10 rounded-lg hover:text-blue02 hover:border-blue02 active:text-blue01 active:border-blue01',
                disabled && 'Button__disabled',
                small && 'Button__small',
                className,
            )}
            onClick={onClick}
            title={title}
            aria-label={title}
            {...(dataTestId && {'data-test-id': dataTestId})}
        >
            {children}
        </button>
    );
}
