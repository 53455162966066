import '../styles/lexical-modal.css';

import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';

function PortalImpl({ onClose, children, title, closeOnClickOutside,}) {
    const modalRef = useRef(null);

    useEffect(() => {
        if (modalRef.current !== null) {
            modalRef.current.focus();
        }
    }, []);

    useEffect(() => {
        let modalOverlayElement = null;
        const handler = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        const clickOutsideHandler = (event) => {
            const target = event.target;
            if (modalRef.current !== null && modalRef.current.contains(target) && closeOnClickOutside) {
                onClose();
            }
        };
        const modelElement = modalRef.current;
        if (modelElement !== null) {
            modalOverlayElement = modelElement.parentElement;
            if (modalOverlayElement !== null) {
                modalOverlayElement.addEventListener('click', clickOutsideHandler);
            }
        }

        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
            if (modalOverlayElement !== null) {
                modalOverlayElement?.removeEventListener('click', clickOutsideHandler);
            }
        };
    }, [closeOnClickOutside, onClose]);

    return (
        <div className="fixed inset-0 flex flex-column items-center justify-center bg-black bg-opacity-80" role="dialog">
            <div className="relative flex flex-col items-stretch gap-6 transform rounded-lg bg-white p-8 text-left shadow-modal sm:my-8 sm:w-full sm:max-w-lg" tabIndex={-1} ref={modalRef}>
                <div className="flex items-center justify-between">
                    <h2 className="font-vg-medium text-lg">{title}</h2>
                    <button
                        className="cursor-pointer"
                        aria-label="Close modal"
                        type="button"
                        onClick={onClose}
                    >
                        <XMarkIcon className="w-6 h-6"/>
                    </button>
                </div>
                
                {children}
            </div>
        </div>
    );
}

export default function Modal({ onClose, children, title, closeOnClickOutside = false}) {
    return createPortal(
        <PortalImpl
            onClose={onClose}
            title={title}
            closeOnClickOutside={closeOnClickOutside}
        >
            {children}
        </PortalImpl>,
        document.body,
    );
}
