import { useState, useContext, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import * as UserConstants from '../../constants/UserConstants';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as MatomoConstants from '../../constants/MatomoConstants';
import UserService from '../../services/UserService';
import OrgService from '../../services/OrgService';
import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';
import { GlobalContext } from '../../context/GlobalContext';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import OrgEntHeader from '../../components/OrgEntHeader';
import OrgEntPanelHeader from '../../components/OrgEntPanelHeader';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import UserTable from '../../components/UserTable';
import UserModal from '../../components/UserModal';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';
import LoginExpiryModal from '../../components/LoginExpiryModal';
import Footer from '../../components/Footer';

export default function UserManagementPage() {
    const {context, setContextValues} = useContext(GlobalContext);

    const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    const [formActive, setFormActive] = useState(false);

    const ORG_USER_EMAIL_TOOLTIP = "This is the email address used to create your Seedkit account.";
    const MEMBER_EMAIL_TOOLTIP = "This is the email address that was assigned to your account by your administrator or organisational account holder. You should contact them if you wish to change it.";

    const ORG_USER_NEW_EMAIL_TOOLTIP = "When you save a new email address, an email will be sent to that address requiring you to verify it and set a new password. Your old email will remain active until you have completed this process.";

    const orgAccountAdmin = useCallback((orgAccountOnly) => {
        let userCreator = false;
        if (context.user) {
            if (context.user.hasOwnProperty("accountHolder") && context.user.accountHolder === true) {
                userCreator = true;
            } else {
                if (!orgAccountOnly) {
                    const roles = context.user.roles && context.user.roles ? context.user.roles : [];
                    for (const role of roles) {
                        const perms = role.permissions ? role.permissions : [];
                        for (const perm of perms) {
                            if (perm.objectType === UserConstants.ObjectTypes.USER && perm.accessTypes.indexOf(UserConstants.AccessTypes.CREATE) > -1) {
                                userCreator = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return userCreator;
    }, [context.user]);

    const getOrgText = useCallback(() => {
        return context.organisation && context.organisation.name ? context.organisation.name : "your organisation";
    }, [context.organisation]);
    
    const getSubtitle = useCallback(() => {
        return orgAccountAdmin(false) ? `Update your account details and manage users for ${getOrgText()}.` : "Update your account details."
    }, [getOrgText, orgAccountAdmin]);

    const getOrgUserText = useCallback(() => {
        if (context.organisation && context.organisation.name) {
            return `This is the list of Seedkit users who are part of ${getOrgText()}.`;
        } else {
            return "This is the list of Seedkit users who are part of your organisation.";
        }
    }, [context.organisation, getOrgText]);

    const getOrgDeleteText = useCallback(() => {
        let txt = "Click the button on the right to begin the process that will lead to the deletion of your organisation's account and all its data. This includes all users, social enterprises, programs, projects, indicator pathways, data and dashboards belonging to your organisation."
        if (context.organisation && context.organisation.name) {
            txt = `Click the button on the right to begin the process that will lead to the deletion of ${getOrgText()}'s account. This includes all users, social enterprises, programs, projects, indicator pathways, data and dashboards belonging to ${getOrgText()}.`;
        }
        return `${txt} When you click the button, an email will be sent to the Seedkit team and they will contact you to confirm your request and finalise the deletion.`;
    }, [context.organisation, getOrgText]);

    const [subtitle, setSubtitle] = useState(getSubtitle());
    const [orgUserText, setOrgUserText] = useState(getOrgUserText());
    const [orgDeleteText, setOrgDeleteText] = useState(getOrgDeleteText());

    const [membersList, setMembersList] = useState([]);
    const [membersLoading, setMembersLoading] = useState(false);
    const [membersLoaded, setMembersLoaded] = useState(false);
    const [noMembersMessage, setNoMembersMessage] = useState("Loading...");

    const [verificationSent, setVerificationSent] = useState(context.user.newEmail ? context.user.newEmail !== "" : false);

    const [deleteProcessing, setDeleteProcessing] = useState(false);

    /* Account Details Form */

    let formValues = {};
    if (context.user) {
        formValues = {
            email: context.user.email ? context.user.email : "",
            firstName: context.user.firstName ? context.user.firstName : "",
            lastName: context.user.lastName ? context.user.lastName : "",
            jobTitle: context.user.jobTitle ? context.user.jobTitle : "",
            newPassword: "",
            confirmNewPassword: "",
            newEmail: context.user.newEmail ? context.user.newEmail : "" 
        };
    } else {
        formValues = {
            email: "",
            firstName: "",
            lastName: "",
            jobTitle: "",
            newPassword: "",
            confirmNewPassword: "",
            newEmail: ""
        };
    }

    const validationSchema = Yup.object().shape({
        newEmail: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address'),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Passwords do not match!")
    });
    
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: formValues
    }

    const { 
        control, 
        handleSubmit,
        reset,
        getValues,
        setValue,
        trigger,
    } = useForm(formOptions);

    const onNewEmailBlur = () => {
        const formNewEmail = getValues("newEmail");
        const contextNewEmail = context.user && context.user.newEmail ? context.user.newEmail : "";
        if (formNewEmail === "" || formNewEmail !== contextNewEmail) {
            setVerificationSent(false);
        }
    }

    const onNewEmailSave = async (e) => {
        e.preventDefault();
        const valid = await trigger("newEmail");
        if (valid && context.user && context.user.id) {
            const formNewEmail = getValues("newEmail");
            const contextNewEmail = context.user.newEmail ? context.user.newEmail : "";
            if (formNewEmail !== contextNewEmail) {
                if (window.confirm("Are you sure want to change your account email to " + formNewEmail + "?")) {
                    const payload = {
                        id: context.user.id,
                        newEmail: formNewEmail,
                        returned: "clean"
                    }
                    UserService.updateUser(payload)
                    .then(response => {
                        setContextValues([{ key: "user", value: response.data.user }]);
                        setVerificationSent(true);
                        alert("Your email has been updated to " + formNewEmail + " and a verification email has been sent to this new email address.");
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.message) {
                            alert(err.message);
                        }
                    });
                } else {
                    setValue("newEmail", contextNewEmail);
                }
            }
        }
    }

    const onEmailChangeEmailResend = (e) => {
        e.preventDefault();
        AuthService.resendEmailChangeEmail()
        .then((_) => {
            const formNewEmail = getValues("newEmail");
            alert("A change verification email has been sent to " + formNewEmail);
        })
        .catch(err => {
            console.log(err);
            if (err.message) {
                alert(err.message);
            }
        });
    }

    const onFirstNameSubmit = (data) => {
        if (context.user && context.user.id) {
            const firstName = context.user.firstName ? context.user.firstName : "";
            if (firstName !== data.firstName && context.user.id) {
                const payload = {
                    id: context.user.id,
                    firstName: data.firstName,
                    returned: "clean"
                }
                UserService.updateUser(payload)
                .then(response => {
                    setContextValues([{ key: "user", value: response.data.user }]);
                })
                .catch(err => {
                    console.log(err);
                    if (err.message) {
                        alert(err.message);
                    }
                })
            }
        }
    }

    const onLastNameSubmit = (data) => {
        if (context.user && context.user.id) {
            const lastName = context.user.lastName ? context.user.lastName : "";
            if (lastName !== data.lastName && context.user.id) {
                const payload = {
                    id: context.user.id,
                    lastName: data.lastName,
                    returned: "clean"
                }
                UserService.updateUser(payload)
                .then(response => {
                    setContextValues([{ key: "user", value: response.data.user }]);
                })
                .catch(err => {
                    console.log(err);
                    if (err.message) {
                        alert(err.message);
                    }
                })
            }
        }
    }

    const onJobTitleSubmit = (data) => {
        if (context.user && context.user.id) {
            const jobTitle = context.user.jobTitle ? context.user.jobTitle : "";
            if (jobTitle !== data.jobTitle && context.user.id) {
                const payload = {
                    id: context.user.id,
                    jobTitle: data.jobTitle,
                    returned: "clean"
                }
                UserService.updateUser(payload)
                .then(response => {
                    setContextValues([{ key: "user", value: response.data.user }]);
                })
                .catch(err => {
                    console.log(err);
                    if (err.message) {
                        alert(err.message);
                    }
                })
            }
        }
    }

    const onSavePasswordClick = async (e) => {
        e.preventDefault();
        const data = getValues();
        const valid = await trigger(["newPassword", "confirmNewPassword"]);

        if (!valid) {
            return;
        }

        const payload = {
            id: context.user.id,
            password: data.newPassword
        };

        UserService.updateUser(payload)
        .then((_) => {
            data.newPassword = "";
            data.confirmNewPassword = "";
            reset(data);
            alert("Password updated successfully");
        })
        .catch(err => {
            console.log(err);
            if (err.message) {
                alert(err.message);
            }
        })
    }

    /* User Table Functions */

    const updateMemberList = (payload, errorMsg, sendInvite) => {
        UserService.updateUser(payload)
        .then(response => {
            setModalSaving(false);
            setModalOpen(false);
            let mList = JSON.parse(JSON.stringify(membersList));
            const index = mList.map(m => m.id).indexOf(response.data.user.id);
            if (index > -1) {
                mList[index] = formatUserForMemberList(response.data.user);
                setMembersList(mList);
                if (sendInvite) {
                    alert("As you have updated this user's email, a verification email will be sent to the user's new email address.");
                    UserService.sendMemberInvite({ 
                        id: response.data.user.id,
                        invitedBy: getInvitedByName() 
                    })
                    .then(response => {
                        //console.log(response);
                    })
                    .catch(err => {
                        console.log(err);
                        alert("Unable to send invite. Please try again later.");
                    });
                }
            } else {
                alert(errorMsg);
            }
        })
        .catch(err => {
            setModalSaving(false);
            setModalOpen(false);
            alert(err.response?.data?.message ? err.response.data.message : errorMsg);
        });
    }

    const resendInvite = (userId) => {
        const payload = {
            id: userId,
            invitedBy: getInvitedByName() 
        }
        UserService.sendMemberInvite(payload)
        .then(response => {
            alert(response.data.message);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to resend invite. Please try again later.");
        });
    }

    const deactivateMember = (userId) => {
        const payload = {
            id: userId,
            inactive: true,
            returned: "clean"
        }
        updateMemberList(payload, "Unable to deactivate user. Please try again later.", false);
    }

    const activateMember = (userId) => {
        const payload = {
            id: userId,
            inactive: false,
            returned: "clean"
        }
        updateMemberList(payload, "Unable to reactivate user. Please try again later.", false);
    }

    const userListActionClicked = (key, index, row) => {
        switch (key) {
            case UiConstants.ActionKeys.NOTIFY:
                resendInvite(row.id);
                break;
            case UiConstants.ActionKeys.EDIT:
                openModalEdit(row);
                break;
            case UiConstants.ActionKeys.DELETE:
                if (window.confirm("Are you sure you want to deactivate this user?")) {
                    deactivateMember(row.id);
                }
                break;
            case UiConstants.ActionKeys.ENABLE:
                if (window.confirm("Are you sure you want to reactivate this user?")) {
                    activateMember(row.id);
                }
                break;
            default:
                break;
        }
    }

    const onAddMemberClick = () => {
        if (context.user && context.user.trialAccount === true) {
            alert("You cannot access this functionality as you are currently on a trial account.");
            return;
        }
        openModalAdd();
    }

    /* User Modal Functions */
    
    const [modalOpen, setModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState([]);
    const [modalSaving, setModalSaving] = useState(false);

    const openModalAdd = () => {
        const params = {
            action: UiConstants.ActionKeys.ADD
        };
        setModalParams(params);
        setModalOpen(true);
        setModalSaving(false);
    }

    const openModalEdit = (details) => {
        const params = {
            action: UiConstants.ActionKeys.EDIT,
            id: details.id,
            firstName: details.firstName,
            lastName: details.lastName,
            jobTitle: details.jobTitle,
            roleId: details.roleId,
            email: details.email
        };
        setModalParams(params);
        setModalOpen(true);
        setModalSaving(false);
    }

    const formatUserForMemberList = (user) => {
        return {
            id: user.id ? user.id : user._id,
            username: user.username,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            name: (user.firstName + " " + user.lastName).trim(),
            accountHolder: user.accountHolder,
            jobTitle: user.jobTitle,
            roleId: user.roles && user.roles.length > 0 ? (user.roles[0].id ? user.roles[0].id : user.roles[0]) : "",
            roleName: user.roleName ? user.roleName : (user.roles && user.roles.length > 0 && user.roles[0].name ? user.roles[0].name : ""),
            inviteSent: user.inviteSent ? user.inviteSent : false,
            inactive: user.inactive ? user.inactive : false,
            verified: user.verified ? user.verified : false
        }
    }

    const getInvitedByName = () => {
        return (`${context.user.firstName ? context.user.firstName : ""} ${context.user.lastName ? context.user.lastName : ""}`).trim();
    }

    const saveNewMember = (details) => {
        const payload = {
            email: details.email,
            firstName: details.firstName,
            lastName: details.lastName,
            jobTitle: details.jobTitle,
            role: details.roleId,
            invitedBy: getInvitedByName()
        }
        UserService.createMemberUser(payload)
        .then(response => {
            setModalSaving(false);
            setModalOpen(false);
            let user = JSON.parse(JSON.stringify(response.data.user));
            user.roleName = details.roleName;
            setMembersList(membersList.concat(formatUserForMemberList(user)));
            const name = (`${details.firstName} ${details.lastName}`).trim();
            const msg = `A new user account has been created ${name ? `for ${name}` : ""} and an activation email has been sent to ${details.email}`;
            alert(msg);
        })
        .catch(err => {
            setModalSaving(false);
            setModalOpen(false);
            console.log(err);
            alert("Unable to create user. Please try again later.");
        });
    }

    const updateMember = (details) => {
        let sendInvite = false;
        let payload = {
            id: details.id,
            returned: "clean"
        };
        if (details.email) {
            payload.email = details.email;
            payload.verified = false;
            sendInvite = true;
        }
        if (details.firstName) {
            payload.firstName = details.firstName;
        }
        if (details.lastName) {
            payload.lastName = details.lastName;
        }
        if (details.jobTitle) {
            payload.jobTitle = details.jobTitle;
        }
        if (details.roleId) {
            payload.roles = [details.roleId];
        }
        updateMemberList(payload, "Unable to update user. Please try again later.", sendInvite);
    }

    const onModalSave = (details) => {
        setModalSaving(true);
        switch (details.action) {
            case UiConstants.ActionKeys.ADD:
                saveNewMember(details);
                break;
            case UiConstants.ActionKeys.EDIT:
                updateMember(details);
                break;
            default:
                break;
        }
    }

    /* Other Interaction Functions */

    function editClicked(e) {
        e.preventDefault();
        setFormActive(!formActive);
    }

    function onAccountDeleteClicked(e) {
        e.preventDefault();
        setDeleteProcessing(true);
        OrgService.sendDeleteEmail()
        .then(response => {
            setDeleteProcessing(false);
            alert(response.data.message);
        })
        .catch(err => {
            console.log(err);
            setDeleteProcessing(false);
            alert(err.response.data.message);
        })
    }

    /* useEffect Functions */

    useEffect(() => {
        if (membersLoaded === false && membersLoading === false) {
            setNoMembersMessage("Loading...");
            setMembersLoading(true);
            OrgService.getMemberList()
            .then(response => {
                setMembersLoading(false);
                setMembersLoaded(true);
                setMembersList(response.data.users);
                setNoMembersMessage("No additional users created for your organisation");
            })
            .catch(err => {
                console.log(err);
                setMembersLoading(false);
                setMembersLoaded(true);
                setMembersList([]);
                setNoMembersMessage("Unable to load user list. Please try again later.");
            });
        }
    }, [membersLoaded, membersLoading]);

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = () => {
        setIsTrial(false);
        setUpgradeModalOpen(false);
    }
    
    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            if (context.user) {
                reset({
                    email: context.user.email ? context.user.email : "",
                    firstName: context.user.firstName ? context.user.firstName : "",
                    lastName: context.user.lastName ? context.user.lastName : "",
                    jobTitle: context.user.jobTitle ? context.user.jobTitle : "",
                    newEmail: context.user.newEmail ? context.user.newEmail : "",
                    newPassword: "",
                    confirmNewPassword: ""
                });
                setSubtitle(getSubtitle());
                setOrgUserText(getOrgUserText());
                setOrgDeleteText(getOrgDeleteText());
                setVerificationSent(context.user.newEmail ? context.user.newEmail !== "" : false);

                setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
            }
            setDataRefresh(false);
        }
    }, [dataRefresh, context.user, setIsTrial, setDataRefresh, reset, getSubtitle, getOrgUserText, getOrgDeleteText]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
        if (context.user && context.user.id) {
            if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
                setTermsModalOpen(false);
            } else {
                setTermsModalOpen(true);
            }
        } else {
            setTermsModalOpen(false);
        }
    }, [context, setTermsModalOpen]);

    /* Login Expiry Modal Functions */

    const [expiryModalOpen, setExpiryModalOpen] = useState(false);

    const onLoginExpiry = () => {
        setExpiryModalOpen(true);
    }

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageCategory.USER_ACCOUNT + " / " + MatomoConstants.PageTitle.USER_MANAGEMENT
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="w-full h-full flex">
            <KitSideNav page="User Management" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                    <KitTopBar 
                        onDataRefresh={() => setDataRefresh(true)} 
                        banner={GeneralConstants.TRIAL_BANNER}
                        onBannerClick={() => onTrialUpgradeClick()}
                        onLoginExpiry={() => onLoginExpiry()}
                    />
                ) : (
                    <KitTopBar onDataRefresh={() => setDataRefresh(true)} onLoginExpiry={() => onLoginExpiry()}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white bg-org bg-contain bg-right-top bg-no-repeat">
                    <OrgEntHeader
                        title="User Management"
                        subtitle={subtitle}
                        showStrap={true}
                        editing={formActive}
                    />
                    <div className="grid grid-cols-12 gap-6 pt-10">
                        <div className="flex col-span-12 justify-end">
                            <Button 
                                variant="solid"
                                size="small" 
                                label={formActive ? UiConstants.DONE_BUTTON_LABEL : UiConstants.EDIT_BUTTON_LABEL}
                                leftIcon="PencilAltIcon" 
                                onClick={editClicked}
                            />
                        </div>
                        <OrgEntPanelHeader title="Account details" subtitle="" className="col-span-5"/>
                        <form className="col-span-7 flex flex-col items-stretch gap-8" autoComplete="off">
                            <Controller
                                name="firstName"
                                control={control}
                                disabled={!formActive}
                                render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                    <TextInput
                                        ref={ref}  
                                        label="First Name"
                                        variant={formActive ? "default" : "disabled"}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={handleSubmit(onFirstNameSubmit)} 
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                disabled={!formActive}
                                render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                    <TextInput
                                        ref={ref}  
                                        label="Last Name"
                                        variant={formActive ? "default" : "disabled"}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={handleSubmit(onLastNameSubmit)} 
                                    />
                                )}
                            />
                            <Controller
                                name="jobTitle"
                                control={control}
                                disabled={!formActive}
                                render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                    <TextInput
                                        ref={ref}  
                                        label="Job Title"
                                        variant={formActive ? "default" : "disabled"}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={handleSubmit(onJobTitleSubmit)} 
                                    />
                                )}
                            />
                            {orgAccountAdmin(true) ? (
                            <>
                                <h6 className="font-vg-medium text-xl text-black">Change Email</h6>
                                <div className="flex flex-col items-stretch gap-4">
                                    <Controller
                                        name="email"
                                        control={control}
                                        disabled={!formActive}
                                        render={({ field, formState }) => (
                                            <TextInput
                                                label="Current Email"
                                                variant="disabled"
                                                tooltip={ORG_USER_EMAIL_TOOLTIP}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="newEmail"
                                        control={control}
                                        disabled={!formActive}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                            <TextInput
                                                ref={ref}
                                                label="New Email"
                                                variant={formActive ? "default" : "disabled"}
                                                tooltip={ORG_USER_NEW_EMAIL_TOOLTIP}
                                                showHelp="always"
                                                help={formState.errors.newEmail?.message}
                                                onChange={onChange}
                                                value={value}
                                                onBlur={onNewEmailBlur} 
                                            />
                                        )}
                                    />
                                    <div className="flex items-center justify-between gap-3">
                                        <Button 
                                            variant="outline"
                                            size="large" 
                                            label="Save Email" 
                                            className="grow-0 shrink-0"
                                            disabled={!formActive}
                                            onClick={onNewEmailSave}
                                        />
                                        <Button 
                                            variant="outline"
                                            size="large" 
                                            label="Resend Verification Email" 
                                            className={`grow shrink${verificationSent ? "" : " invisible"}`}
                                            disabled={!formActive}
                                            onClick={onEmailChangeEmailResend}
                                        />
                                    </div>
                                </div>
                            </>
                            ) : (
                                <Controller
                                    name="email"
                                    control={control}
                                    disabled={!formActive}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Email"
                                            variant="disabled"
                                            tooltip={MEMBER_EMAIL_TOOLTIP}
                                            {...field}
                                        />
                                    )}
                                />
                            )}
                            <h6 className="font-vg-medium text-xl text-black">Change Password</h6>
                            <div className="flex flex-col items-stretch gap-4">
                                <Controller
                                    name="newPassword"
                                    control={control}
                                    disabled={!formActive}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="New Password"
                                            variant={formActive ? "default" : "disabled"}
                                            type="password"
                                            autoComplete="new-password"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="confirmNewPassword"
                                    control={control}
                                    disabled={!formActive}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Confirm New Password"
                                            variant={formActive ? "default" : "disabled"}
                                            type="password"
                                            showHelp="always"
                                            help={formState.errors.confirmNewPassword?.message}
                                            autoComplete="new-password"
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="flex items-center">
                                    <Button 
                                        variant="outline"
                                        size="large" 
                                        label="Save Password" 
                                        disabled={!formActive}
                                        onClick={onSavePasswordClick}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {orgAccountAdmin(false) && (
                    <div className="flex flex-col item-stretch gap-6">
                        <div className="flex flex-col gap-2">
                            <h4 className="font-vg-medium text-3.5xl text-black">Organisation Users</h4>
                            <p className="font-vg-book text-base text-grey">
                                {orgUserText}
                            </p>
                            <p className="font-vg-book text-base text-grey">
                                You can add multiple users to the organisation attached to your Seedkit account and they will be able to use Seedkit according to the level of access you give them: 
                            </p>
                            <ul className="font-vg-book text-base text-grey pl-5 list-disc">
                                <li><strong>Administrator</strong> will be able to create multiple user roles and specify their permissions; create and archive new enterprises and programs/projects.</li>
                                <li className="my-3"><strong>Team Member (Full Access)</strong> will be able to read, write, select & create indicators, submit data, create dashboards, and download reports from dashboards.</li>
                                <li><strong>Team Member (Read Only)</strong> will be able to read content on Seedkit and download reports from dashboards.</li>
                            </ul>
                        </div>
                        <UserTable
                            columns={["name", "roleName", "email"]}
                            data={membersList}
                            variant="striped"
                            hideHeaders="true"
                            border="true"
                            scrolling="false"
                            actions={UiConstants.MEMBER_MANAGEMENT_ACTIONS}
                            onActionClick={userListActionClicked}
                            noDataMessage={noMembersMessage}
                        />
                        <div className="flex justify-end">
                            <Button variant="outline" size="large" label="Add A New User" onClick={onAddMemberClick}/>
                        </div>
                    </div>
                    )}
                    {orgAccountAdmin(true) && (
                    <div className="flex flex-col item-stretch gap-6">
                        <h4 className="font-vg-medium text-3.5xl text-black">Delete Account</h4>
                        <div className="flex gap-6 items-center">
                            <p className="font-vg-book text-base text-grey grow">
                                {orgDeleteText}
                            </p>
                            <div className="w-96 grow-0 shrink-0 flex flex-col items-center">
                                <Button 
                                    size="large"
                                    variant="delete"
                                    className={deleteProcessing ? "hidden" : "block"}
                                    label="Contact Seedkit Team To Organise Deletion"
                                    onClick={onAccountDeleteClicked}
                                />
                                <p className={`font-vg-regular text-base text-red ${deleteProcessing ? "block" : "hidden"}`}>
                                    Sending Email...
                                </p>
                            </div>
                        </div>
                    </div>
                    )}
                    <Footer/>
                </div>
            </div>
            <UserModal
                params={modalParams}
                open={modalOpen}
                onSave={(details) => onModalSave(details)}
                onClose={() => setModalOpen(false)} 
                saving={modalSaving}
            />
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(_) => onTrialUpgradeDone()}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
            <LoginExpiryModal
                open={expiryModalOpen}
            />
        </div>
    );
}