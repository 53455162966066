import {useCallback, useMemo, useState} from 'react';

import LexicalModal from '../components/LexicalModal';

export default function useLexicalModal() {
    const [modalContent, setModalContent] = useState(null);

    const onClose = useCallback(() => {
        setModalContent(null);
    }, []);

    const modal = useMemo(() => {
        if (modalContent === null) {
            return null;
        }
        const {title, content, closeOnClickOutside} = modalContent;
        return (
            <LexicalModal
                onClose={onClose}
                title={title}
                closeOnClickOutside={closeOnClickOutside}
            >
                {content}
            </LexicalModal>
        );
    }, [modalContent, onClose]);

    const showModal = useCallback((title, getContent, closeOnClickOutside = false) => {
        setModalContent({
            closeOnClickOutside,
            content: getContent(onClose),
            title,
        });
    }, [onClose]);

    return [modal, showModal];
}
