import { useState, useEffect, useId } from 'react';
import { TextNode } from 'lexical';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { $generateHtmlFromNodes } from '@lexical/html';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

import LexicalUtils from '../utils/LexicalUtils'; 

import LexicalToolbarPlugin from './LexicalToolbarPlugin';
import LexicalContentLoadPlugin from './LexicalContentLoadPlugin';
import LexicalLinkPlugin from './LexicalLinkPlugin';
import LexicalFloatingLinkEditorPlugin from './LexicalFloatingLinkEditorPlugin';
import LexicalPlaceholder from './LexicalPlaceholder';
import LexicalEditorTheme from '../themes/LexicalEditorTheme';
import LexicalImagePlugin from './LexicalImagePlugin';
import LexicalInlineImagePlugin from './LexicalInlineImagePlugin';
import LexicalEmbedPlugin from './LexicalEmbedPlugin';
import LexicalYouTubePlugin from './LexicalYouTubePlugin';
import LexicalTableActionMenuPlugin from './LexicalTableActionMenuPlugin';
import LexicalTableCellResizer from './LexicalTableCellResizer';
import { LexicalExtendedTextNode } from './LexicalExtendedTextNode';
import { LexicalLayoutPlugin } from './LexicalLayoutPlugin';
import { LexicalYouTubeNode } from './LexicalYouTubeNode';
import { LexicalImageNode } from './LexicalImageNode';
import { LexicalInlineImageNode } from './LexicalInlineImageNode';
import { LexicalLayoutContainerNode } from './LexicalLayoutContainerNode';
import { LexicalLayoutItemNode } from './LexicalLayoutItemNode';
import { TableContext } from './LexicalTableExtra';

export default function LexicalRichTextEditor({ placeholder, content, height, heightRef, onChange }) {
    const DEFAULT_PLACEHOLDER = "Start typing text here. Use the toolbar above to format it and add other elements...";
    const newId = useId();
    const useKey = `lexical-${newId}`;
    const usePlaceholder = placeholder ? placeholder : DEFAULT_PLACEHOLDER;

    const [editorContent, setEditorContent] = useState(content); 

    const [isLinkEditMode, setIsLinkEditMode] = useState(false);
    const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

    const [innerStyle, setInnerStyle] = useState(height ? { height } : {});

    const onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    const onError = (error) => {
        console.log(error);
    }

    const initialConfig = {
        namespace: 'Seedkit',
        nodes: [
            LexicalExtendedTextNode,
            { replace: TextNode, with: (node) => new LexicalExtendedTextNode(node.__text) },
            HeadingNode, 
            ListNode,
            ListItemNode,
            QuoteNode,
            LinkNode,
            AutoLinkNode,
            HorizontalRuleNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            LexicalImageNode,
            LexicalInlineImageNode,
            LexicalYouTubeNode,
            LexicalLayoutContainerNode,
            LexicalLayoutItemNode
        ],
        onError(error) {
            onError(error);
        },
        theme: LexicalEditorTheme,
    };

    const onLexicalChange = (editorState, editor) => {
        editorState.read(() => {
            const html = $generateHtmlFromNodes(editor, null);
            setEditorContent(html);
            if (onChange) {
                onChange(html);
            }
        })
    }

    useEffect(() => {
        const updateViewPortWidth = () => {
            const isNextSmallWidthViewport = LexicalUtils.CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;
    
            if (isNextSmallWidthViewport !== isSmallWidthViewport) {
                setIsSmallWidthViewport(isNextSmallWidthViewport);
            }
        };
        updateViewPortWidth();
        window.addEventListener('resize', updateViewPortWidth);
    
        return () => {
            window.removeEventListener('resize', updateViewPortWidth);
        };
    }, [isSmallWidthViewport]);

    useEffect(() => {
        if (heightRef) {
            setInnerStyle({ height: heightRef.current.clientHeight - 48});
        }
    }, [heightRef, setInnerStyle]);
    
    return (
        <LexicalComposer key={useKey} initialConfig={initialConfig}>
            <TableContext>
                <div className="editor-shell editor-container">
                <LexicalToolbarPlugin setIsLinkEditMode={setIsLinkEditMode}/>
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={
                            <div className="editor-scroller" style={innerStyle}>
                                <div className="editor" ref={onRef}>
                                    <ContentEditable className="editor-input ContentEditable__root"/>
                                </div>
                            </div>
                        }
                        placeholder={<LexicalPlaceholder>{usePlaceholder}</LexicalPlaceholder>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <LexicalContentLoadPlugin initialContent={editorContent}/>
                    <ListPlugin/>
                    <CheckListPlugin/>
                    <LexicalLinkPlugin/>
                    <HorizontalRulePlugin/>
                    <LexicalImagePlugin/>
                    <LexicalInlineImagePlugin/>
                    <TablePlugin
                        hasCellMerge={true}
                        hasCellBackgroundColor={true}
                    />
                    <LexicalTableCellResizer/>
                    <LexicalEmbedPlugin/>
                    <LexicalYouTubePlugin/>
                    <HistoryPlugin/>
                    <AutoFocusPlugin/>
                    <LexicalLayoutPlugin/>
                    <OnChangePlugin onChange={onLexicalChange} ignoreSelectionChange/>
                    {floatingAnchorElem && !isSmallWidthViewport && (
                        <>  
                            <LexicalFloatingLinkEditorPlugin
                                anchorElem={floatingAnchorElem}
                                isLinkEditMode={isLinkEditMode}
                                setIsLinkEditMode={setIsLinkEditMode}
                            /> 
                            <LexicalTableActionMenuPlugin
                                anchorElem={floatingAnchorElem}
                                cellMerge={true}
                            />
                        </>
                    )}
                </div>
                </div>
            </TableContext>
        </LexicalComposer>
    );
}
