import '../styles/lexical-input.css';

import {
    AutoEmbedOption,
    LexicalAutoEmbedPlugin,
    URL_MATCHER,
} from '@lexical/react/LexicalAutoEmbedPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';

import useLexicalModal from '../hooks/useLexicalModal';
import LexicalButton from './LexicalButton';
import { DialogActions } from './LexicalDialog';
import { INSERT_YOUTUBE_COMMAND } from './LexicalYouTubePlugin';

export const YouTubeEmbedConfig = {
    contentName: 'Youtube Video',

    exampleUrl: 'https://www.youtube.com/watch?v=???????',

    // Icon for display.
    icon: <i className="icon youtube"/>,

    insertNode: (editor, result) => {
        editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, result.id);
    },
    
    keywords: ['youtube', 'video'],

    // Determine if a given URL is a match and return url data.
    parseUrl: async (url) => {
        const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);
        const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;
        if (id != null) {
            return {
                id,
                url,
            };
        }
        return null;
    },

    type: 'youtube-video',
}

export const EmbedConfigs = [
    YouTubeEmbedConfig,
];

function AutoEmbedMenuItem({ index, isSelected, onClick, onMouseEnter, option }) {
    let className = 'item';
    if (isSelected) {
        className += ' selected';
    }
    return (
        <li
            key={option.key}
            tabIndex={-1}
            className={className}
            ref={option.setRefElement}
            role="option"
            aria-selected={isSelected}
            id={'typeahead-item-' + index}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
        >
            <span className="text">{option.title}</span>
        </li>
    );
}

function AutoEmbedMenu({ options, selectedItemIndex, onOptionClick, onOptionMouseEnter }) {
    return (
        <div className="typeahead-popover">
            <ul>
                {options.map((option, i) => (
                <AutoEmbedMenuItem
                    index={i}
                    isSelected={selectedItemIndex === i}
                    onClick={() => onOptionClick(option, i)}
                    onMouseEnter={() => onOptionMouseEnter(i)}
                    key={option.key}
                    option={option}
                />
                ))}
            </ul>
        </div>
    );
}

const debounce = (callback, delay) => {
    let timeoutId;
    return (text) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(text);
        }, delay);
    };
};
 

export function AutoEmbedDialog({ embedConfig, onClose }) {
    const [text, setText] = useState('');
    const [editor] = useLexicalComposerContext();
    const [embedResult, setEmbedResult] = useState(null);
    
    const validateText = useMemo(
        () => debounce((inputText) => {
            const urlMatch = URL_MATCHER.exec(inputText);
            if (embedConfig != null && inputText != null && urlMatch != null) {
                Promise.resolve(embedConfig.parseUrl(inputText)).then(
                (parseResult) => {
                    setEmbedResult(parseResult);
                },
                );
            } else if (embedResult != null) {
                setEmbedResult(null);
            }
        }, 200), [embedConfig, embedResult],
    );
    
    const onClick = () => {
        if (embedResult != null) {
            embedConfig.insertNode(editor, embedResult);
            onClose();
        }
    };
    
    return (
        <div className="w-full flex flex-col items-stretch gap-4">
            <div className="Input__wrapper">
                <input
                    type="text"
                    className="Input__input"
                    placeholder={embedConfig.exampleUrl}
                    value={text}
                    data-test-id={`${embedConfig.type}-embed-modal-url`}
                    onChange={(e) => {
                        const {value} = e.target;
                        setText(value);
                        validateText(value);
                    }}
                />
            </div>
            <DialogActions>
                <LexicalButton
                    disabled={!embedResult}
                    onClick={onClick}
                    data-test-id={`${embedConfig.type}-embed-modal-submit-btn`}
                >
                    Embed
                </LexicalButton>
            </DialogActions>
        </div>
    );
}

export default function AutoEmbedPlugin() {
    const [modal, showModal] = useLexicalModal();
    
    const openEmbedModal = (embedConfig) => {
        showModal(`Embed ${embedConfig.contentName}`, (onClose) => (
            <AutoEmbedDialog embedConfig={embedConfig} onClose={onClose} />
        ));
    };
    
    const getMenuOptions = (activeEmbedConfig, embedFn, dismissFn) => {
        return [
            new AutoEmbedOption('Dismiss', {
                onSelect: dismissFn,
            }),
            new AutoEmbedOption(`Embed ${activeEmbedConfig.contentName}`, {
                onSelect: embedFn,
            }),
        ];
    };
    
    return (
        <>
        {modal}
        <LexicalAutoEmbedPlugin
            embedConfigs={EmbedConfigs}
            onOpenEmbedModalForConfig={openEmbedModal}
            getMenuOptions={getMenuOptions}
            menuRenderFn={(
                anchorElementRef,
                {selectedIndex, options, selectOptionAndCleanUp, setHighlightedIndex},
            ) => anchorElementRef.current ? ReactDOM.createPortal(
                <div
                    className="typeahead-popover auto-embed-menu"
                    style={{
                        marginLeft: `${Math.max(
                        parseFloat(anchorElementRef.current.style.width) - 200,
                        0,
                        )}px`,
                        width: 200,
                    }}
                >
                    <AutoEmbedMenu
                        options={options}
                        selectedItemIndex={selectedIndex}
                        onOptionClick={(option, index) => {
                            setHighlightedIndex(index);
                            selectOptionAndCleanUp(option);
                        }}
                        onOptionMouseEnter={(index) => {
                            setHighlightedIndex(index);
                        }}
                    />
                </div>,
                anchorElementRef.current,
                )
                : null
            }
        />
        </>
    );
}
 
 
  