import '../styles/lexical-dialog.css';

export function DialogButtonsList({children}) {
    return <div className="flex flex-col items-stretch gap-4">{children}</div>;
}

export function DialogActions({
    'data-test-id': dataTestId,
    children,
}) {
    return (
        <div className="flex flex-col items-stretch gap-4" data-test-id={dataTestId}>
            {children}
        </div>
    );
}