import { useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import UiUtils from '../../utils/UiUtils';
import * as MatomoConstants from '../../constants/MatomoConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function HelpPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageTitle.HELP
        });
        // eslint-disable-next-line
    }, []);

    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-16">
                <Header title="Help"/>
                <Footer/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-6 flex flex-col items-stretch gap-12">
                <Header title="Help"/>
                <Footer/>
            </div>
        )}
        </>
    );
}