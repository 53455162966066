import { useRef, useId, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import $ from 'jquery';

import * as DashboardConstants from "../constants/DashboardConstants";
import * as UiConstants from "../constants/UiConstants";
import UiUtils from '../utils/UiUtils';

import DashboardMoreMenu from './DashboardMoreMenu';

export default function DashboardCanvasTextCard({ id, row, col, width, height, details, mode, onCanvasElementAction }) {

    const newId = useId();
    const cardId = id ? id : newId;

    const quillRef = useRef(null);

    let rowColClass = "";

    let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
    let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
    let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
    let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

    switch (rowStart) {
        case 1:
            rowColClass = "row-start-1";
            break;
        case 2:
            rowColClass = "row-start-2";
            break;
        case 3:
            rowColClass = "row-start-3";
            break;
        default:
            break;
    }

    switch (colStart) {
        case 1:
            rowColClass = `${rowColClass} col-start-1`;
            break;
        case 2:
            rowColClass = `${rowColClass} col-start-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-start-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-start-4`;
            break;
        default:
            break;
    }

    switch (rowEnd) {
        case 2:
            rowColClass = `${rowColClass} row-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} row-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} row-end-4`;
            break;
        default:
            break;
    }

    switch (colEnd) {
        case 2:
            rowColClass = `${rowColClass} col-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-end-4`;
            break;
        case 5:
            rowColClass = `${rowColClass} col-end-5`;
            break;
        default:
            break;
    }

    let outerClass = `flex h-full flex-col items-stretch gap-6 p-6 overflow-hidden ${rowColClass}`;
    outerClass = `${outerClass} ${mode === DashboardConstants.CanvasMode.EDIT ? "rounded-lg bg-grey04 border border-grey03" : "bg-white"}`;

    const quillToolbar = width === 1 ? 
        [
            ['bold', 'italic', 'underline', {'list': 'ordered'}, {'list': 'bullet'}, 'link'],
        ] :
        [
            [{size: []}],
            ['bold', 'italic', 'underline', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ];

    const quillFormats = width === 1 ?
        ['bold', 'italic', 'underline', 'list', 'bullet', 'link'] :
        ['size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link', 'blockquote'];

    const quillPlaceholder = "Start typing text here. Use the toolbar above to format it and add links";

    const applyFormatting = (rawText, editMode) => {
        let formattedText = rawText
        .replace(/<p>/g, '<p class="font-vg-book text-xs2 leading-150">')
        .replace(/class="ql-indent-1"/g, 'class="font-vg-book text-xs2 leading-150 pl-12 ql-indent-1"')
        .replace(/class="ql-indent-2"/g, 'class="font-vg-book text-xs2 leading-150 pl-24 ql-indent-2"')
        .replace(/class="ql-indent-3"/g, 'class="font-vg-book text-xs2 leading-150 pl-36 ql-indent-3"')
        .replace(/class="ql-indent-4"/g, 'class="font-vg-book text-xs2 leading-150 pl-48 ql-indent-4"')
        .replace(/class="ql-indent-5"/g, 'class="font-vg-book text-xs2 leading-150 pl-60 ql-indent-5"')
        .replace(/class="ql-indent-6"/g, 'class="font-vg-book text-xs2 leading-150 pl-72 ql-indent-6"')
        .replace(/class="ql-indent-7"/g, 'class="font-vg-book text-xs2 leading-150 pl-84 ql-indent-7"')
        .replace(/class="ql-indent-8"/g, 'class="font-vg-book text-xs2 leading-150 pl-96 ql-indent-8"')
        .replace(/class="ql-size-small"/g, 'class="font-vg-book text-xxs ql-size-small"')
        .replace(/class="ql-size-large"/g, 'class="font-vg-book text-xl ql-size-large"')
        .replace(/class="ql-size-huge"/g, 'class="font-vg-book text-3.5xl2 ql-size-huge"')
        .replace(/<ul>/g, '<ul class="list-disc list-inside pl-6">')
        .replace(/<ol>/g, '<ol class="list-decimal list-inside pl-6">');

        // Now use jquery to update list items
        let $html = $($.parseHTML(formattedText));
        $html.find("li").each(function() {
            $(this).addClass("font-vg-book text-xs2 leading-150");
            let replaceDone = false;
            DashboardConstants.IndentReplacements.forEach(rep => {
                if ($(this).hasClass(rep.REMOVE)) {
                    $(this).removeClass(rep.REMOVE);
                    $(this).addClass(rep.ADD);
                    if ($(this).parent().prop("tagName").toLowerCase() === "ol") {
                        $(this).attr("type", rep.TYPE);
                    }
                    replaceDone = true;
                }
            });
            if (!replaceDone) {
                $(this).addClass("pl-6");
            }
        })
        formattedText = $('<div>').append($html).html();

        if (editMode !== DashboardConstants.CanvasMode.EDIT) {
            formattedText = formattedText.replace(/<a /g, '<a target="_blank" ');
        }
        return formattedText;
    }
    
    const onTextBlur = (e) => {
        const editor = quillRef.current.getEditor();
        const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);
        const text = applyFormatting(unprivilegedEditor.getHTML(), mode);
        if (onCanvasElementAction) {
            onCanvasElementAction({
                action: DashboardConstants.ActionType.TEXT_UPDATED,
                args: {
                    id: details.id,
                    text: UiUtils.sanitiseHtml(text)
                }
            });
        }
    }

    const onMoreMenuClick = (details) => {
        if (onCanvasElementAction) {
            switch (details.action) {
                case DashboardConstants.ActionType.ELEMENT_MENU_EDIT:
                    onCanvasElementAction({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_EDIT,
                        args: {
                            id: details.args.id,
                            row: row,
                            col: col,
                            width: width,
                            height: height
                        }
                    });
                    break;
                case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
                    if (window.confirm("Are you sure you want to delete this story/commentary element?")) {
                        onCanvasElementAction({
                            action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                            args: {
                                id: details.args.id,
                                row: row,
                                col: col
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }

    const [allExportDone, setAllExportDone] = useState(false);

    useEffect(() => {
        if (document.querySelector('.ql-toolbar')) {
            document.querySelector('.ql-toolbar').addEventListener("mousedown", function(e) {
                e.preventDefault();
            });
        }
    });

    useEffect(() => {
        if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
            if (!allExportDone) {
                if (onCanvasElementAction) {
                    onCanvasElementAction({
                        action: DashboardConstants.ActionType.ELEMENT_LOAD_COMPLETE,
                        args: {
                            type: "text",
                            element: details.id,
                            success: true
                        }
                    });
                }
                setAllExportDone(true);
            }
        } else {
            setAllExportDone(false)
        }
    }, [mode, details, allExportDone, onCanvasElementAction]);

    return (
        <div key={`canvas-text-card-${cardId}`} className={outerClass}>
            <div className="flex items-center justify-between grow-0">
                <h6 className="font-vg-regular text-grey text-base leading-130 grow">{details.title}</h6>
                {mode === DashboardConstants.CanvasMode.EDIT && (
                    <DashboardMoreMenu
                        items={UiConstants.DASH_MORE_MENU_ITEMS}
                        params={{id: details.id}}
                        onMenuClick={onMoreMenuClick}
                    />
                )}
            </div>
            {mode === DashboardConstants.CanvasMode.EDIT ? (
                <div className="grow overflow-hidden border-b" onBlur={onTextBlur} data-text-editor="name">
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        className="w-full h-full"
                        value={details.text}
                        placeholder={quillPlaceholder}
                        modules={{
                            toolbar: quillToolbar
                        }}
                        formats={quillFormats}
                        bounds={'[data-text-editor="name"]'}
                    />
                </div>
            ) : (
                <div className="font-vg-book grow overflow-hidden" dangerouslySetInnerHTML={{__html: applyFormatting(details.text, mode)}}></div>
            )}
        </div>
    );
}