import { useState } from 'react';

import LexicalButton from './LexicalButton';
import LexicalDropDown from './LexicalDropDown';
import { INSERT_LAYOUT_COMMAND } from './LexicalLayoutPlugin';

const LAYOUTS = [
   { label: '2 columns (equal width)', value: '1fr 1fr' },
   { label: '2 columns (25% - 75%)', value: '1fr 3fr' },
   { label: '3 columns (equal width)', value: '1fr 1fr 1fr' },
   { label: '3 columns (25% - 50% - 25%)', value: '1fr 2fr 1fr' },
   { label: '4 columns (equal width)', value: '1fr 1fr 1fr 1fr' },
];

export default function InsertLayoutDialog({ activeEditor, onClose }) {
   const [layoutOption, setLayoutOption] = useState(LAYOUTS[0]);

   const onClick = () => {
      activeEditor.dispatchCommand(INSERT_LAYOUT_COMMAND, layoutOption.value);
      onClose();
   };

   return (
      <div className="flex flex-col items-stretch gap-4">
         <LexicalDropDown
            variant="modal"
            options={LAYOUTS}
            selectedOption={layoutOption}
            onChange={(selection) => setLayoutOption(selection)}
         />
         <LexicalButton className="mt-5" onClick={onClick}>Insert</LexicalButton>
      </div>
   );
}
