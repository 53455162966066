import { QuestionMarkCircleIcon, PaintBrushIcon, UserGroupIcon, BriefcaseIcon, 
    GlobeAsiaAustraliaIcon, FingerPrintIcon, HeartIcon, HomeModernIcon,
    ChartBarSquareIcon, BuildingLibraryIcon, AcademicCapIcon,
    CurrencyDollarIcon, CogIcon, UserIcon, PresentationChartLineIcon,
    PuzzlePieceIcon
} from '@heroicons/react/24/solid';
import sanitizeHtml from 'sanitize-html';

import * as UiConstants from '../constants/UiConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';

let UiUtils = {};

UiUtils.isMobile = (width) => {
    return width < 1024;
};

UiUtils.isXLargeDesktop = (width) => {
    return width > 1400;
};

UiUtils.isLargeDesktop = (width) => {
    return width > 1200;
};

UiUtils.classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
};

UiUtils.getYearOptions = () => {
    const year = new Date().getFullYear();
    let options = [UiConstants.EMPTY_OPTION];
    for (let y = year; y >= year - UiConstants.TOTAL_OPTIONS_YEARS; y-- ) {
        options.push({ value: y, label: y });
    }
    return options;
};

UiUtils.capitalise = (text, allWords) => {
    if (text.indexOf("/") > -1) {
        const parts = text.split("/");
        return parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join("/");
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
}

UiUtils.capitaliseAllWords = (text) => {
    const parts = text.split(" ");
    return parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(" ");
}

UiUtils.getOptionFromValue = (value, options) => {
    let option = UiConstants.EMPTY_OPTION;
    const index = options.map(o => o.value).indexOf(value);
    if (index > -1) {
        option = options[index];
    }
    return option;
}

UiUtils.getOptionsFromValues = (values, options) => {
    let retOpts = [];
    values.forEach(v => {
        const index = options.map(o => o.value).indexOf(v);
        if (index > -1) {
            retOpts.push(options[index]);
        }
    });
    return retOpts;
}

UiUtils.getOptionsFromFields = (data, options) => {
    let retOpts = [];
    options.forEach(o => {
        if (data.hasOwnProperty(o.value) && data[o.value] === true) {
            retOpts.push(o);
        }
    });
    return retOpts;
}

UiUtils.setFieldsFromOptions = (data, values, options) => {
    let d = JSON.parse(JSON.stringify(data));
    options.forEach(o => {
        d[o.value] = false;
    });
    values.forEach(v => {
        d[v.value] = true;
    });
    return d;
};

UiUtils.getDomainIcon = (domain) => {
    let icon = null;
    switch (domain) {
        case IndicatorConstants.Domain.ARTS:
            icon = PaintBrushIcon;
            break;
        case IndicatorConstants.Domain.COMMUNITY:
            icon = UserGroupIcon;
            break;
        case IndicatorConstants.Domain.COLLABORATION:
            icon = PuzzlePieceIcon;
            break;
        case IndicatorConstants.Domain.WORK:
            icon = BriefcaseIcon;
            break;
        case IndicatorConstants.Domain.ENVIRONMENT:
            icon = GlobeAsiaAustraliaIcon;
            break;
        case IndicatorConstants.Domain.FIRST_NATIONS:
            icon = FingerPrintIcon;
            break;
        case IndicatorConstants.Domain.HEALTH:
            icon = HeartIcon;
            break;
        case IndicatorConstants.Domain.HOUSING:
            icon = HomeModernIcon;
            break;
        case IndicatorConstants.Domain.IMPROVING_SYSTEMS:
        case IndicatorConstants.Domain.IMPROVING_SYSTEMS_ALT:
            icon = ChartBarSquareIcon;
            break;
        case IndicatorConstants.Domain.EQUITY:
            icon = BuildingLibraryIcon;
            break;
        case IndicatorConstants.Domain.EDUCATION:
            icon = AcademicCapIcon;
            break;
        case IndicatorConstants.Domain.FINANCIAL_MANAGEMENT:
            icon = CurrencyDollarIcon;
            break;
        case IndicatorConstants.Domain.PEOPLE_MANAGEMENT:
            icon = UserIcon;
            break;
        case IndicatorConstants.Domain.GOVERNANCE:
            icon = CogIcon;
            break;
        case IndicatorConstants.Domain.SALES_MARKETING:
        case IndicatorConstants.Domain.SALES_MARKETING_ALT:
            icon = PresentationChartLineIcon;
            break;
        default:
            icon = QuestionMarkCircleIcon;
            break;
    }
    return icon;
};

UiUtils.checkUserAccess = (roles, object, type) => {
    let hasAccess = false;
    roles.forEach(role => {
        role.permissions.forEach(perm => {
            if (perm.objectType === object) {
                if (perm.accessTypes.indexOf(type) > -1) {
                hasAccess = true;
                }
            }
        });
    });
    return hasAccess;
}

UiUtils.getSVGIcon = (name, fill, css) => {
    let className = css ? css : "w-6 h-6 shrink-0 grow-0";
    switch (name) {
        case "bug-slash":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className={className} fill={fill}>
                <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L477.4 348.9c1.7-9.4 2.6-19 2.6-28.9h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H479.7c-1.1-14.1-5-27.5-11.1-39.5c.7-.6 1.4-1.2 2.1-1.9l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-.7 .7-1.3 1.4-1.9 2.1C409.2 164.1 393.1 160 376 160H264c-8.3 0-16.3 1-24 2.8L38.8 5.1zm392 430.3L336 360.7V479.2c36.6-3.6 69.7-19.6 94.8-43.8zM166.7 227.3c-3.4 9-5.6 18.7-6.4 28.7H96c-17.7 0-32 14.3-32 32s14.3 32 32 32h64c0 24.6 5.5 47.8 15.4 68.6c-2.2 1.3-4.2 2.9-6 4.8l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l63.1-63.1c24.5 21.8 55.8 36.2 90.3 39.6V335.5L166.7 227.3zM320 0c-53 0-96 43-96 96v3.6c0 15.7 12.7 28.4 28.4 28.4H387.6c15.7 0 28.4-12.7 28.4-28.4V96c0-53-43-96-96-96z"/>
                </svg>
            );
        case "news":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className} fill={fill}>
                <path d="M160 64c-17.7 0-32 14.3-32 32V416c0 11.7-3.1 22.6-8.6 32H432c26.5 0 48-21.5 48-48V96c0-17.7-14.3-32-32-32H160zM64 480c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64v32c-17.7 0-32 14.3-32 32V416c0 17.7 14.3 32 32 32s32-14.3 32-32V96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V400c0 44.2-35.8 80-80 80H64zM384 112c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zM160 304c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16zm32-144H320V128H192v96zM160 120c0-13.3 10.7-24 24-24H328c13.3 0 24 10.7 24 24V232c0 13.3-10.7 24-24 24H184c-13.3 0-24-10.7-24-24V120z"/>
                </svg>
            );
        case "half-star":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={className} fill={fill}>
                <path d="M288 0c-12.2 .1-23.3 7-28.6 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3L288 439.8V0zM429.9 512c1.1 .1 2.1 .1 3.2 0h-3.2z"/>
                </svg>
            );
        case "cresent-moon":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.867 91.853" className={className} fill={fill}>
                <path d="m45.935 91.853c25.316 0 45.932-20.603 45.932-45.925 0-25.333-20.613-45.928-45.932-45.928-25.333 0-45.935 20.595-45.935 45.928 0.0041 25.319 20.603 45.925 45.935 45.925zm0-82.352c1.2871 0 2.5563 0.06339 3.8185 0.20257-12.865 6.9592-21.645 20.554-21.645 36.223 0 15.663 8.7588 29.261 21.637 36.22-1.2526 0.13092-2.5232 0.20258-3.8104 0.20258-20.092 0-36.428-16.328-36.428-36.424 0-20.095 16.337-36.428 36.428-36.428z" strokeWidth=".35278"/>
                </svg>
            );
        case "full-moon":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.853 91.86" className={className} fill={fill}>
                <path d="m91.853 45.928c0-25.324-20.607-45.928-45.928-45.928-25.328 0-45.925 20.599-45.925 45.928 0 25.32 20.595 45.932 45.925 45.932 25.319 0 45.928-20.607 45.928-45.932zm-45.928 36.431c-20.084 0-36.428-16.337-36.428-36.428 0-20.088 16.345-36.424 36.428-36.424 20.088 0 36.424 16.337 36.424 36.424 0.0041 20.088-16.337 36.428-36.424 36.428z" strokeWidth=".35278"/>
                </svg>
            );
        default:
            return (<></>);
    }
}

UiUtils.sanitiseHtml = (html) => {
    return sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'iframe' ]),
        allowedAttributes: {
            '*': [ '*' ]
        },
        allowedIframeHostnames: ['www.youtube.com', 'www.youtube-nocookie.com'],
        allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
        parseStyleAttributes: false
    });
}

export default UiUtils;