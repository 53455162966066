import '../styles/lexical-inline-image-node.css';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $createRangeSelection,
    $getSelection,
    $insertNodes,
    $isNodeSelection,
    $isRootOrShadowRoot,
    $setSelection,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    createCommand,
    DRAGOVER_COMMAND,
    DRAGSTART_COMMAND,
    DROP_COMMAND
} from 'lexical';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import {
    $createInlineImageNode,
    $isInlineImageNode,
    LexicalInlineImageNode
} from './LexicalInlineImageNode';
import LexicalUtils from '../utils/LexicalUtils';
import LexicalButton from './LexicalButton';
import { DialogActions } from './LexicalDialog';
import LexicalFileInput from './LexicalFileInput';
//import LexicalSelect from './LexicalSelect';
import LexicalDropDown from './LexicalDropDown';
import LexicalTextInput from './LexicalTextInput';

const POSITION_OPTIONS = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
    { label: "Full Width", value: "full"}
];

const getDOMSelection = (targetWindow) =>
    LexicalUtils.CAN_USE_DOM ? (targetWindow || window).getSelection() : null;

export const INSERT_INLINE_IMAGE_COMMAND = createCommand('INSERT_INLINE_IMAGE_COMMAND');

export function InsertInlineImageDialog({ activeEditor, onClose }) {
    const hasModifier = useRef(false);

    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');
    const [showCaption, setShowCaption] = useState(false);
    const [position, setPosition] = useState('left');
    const [positionOption, setPositionOption] = useState(POSITION_OPTIONS[0]);

    const isDisabled = src === '';

    const handleShowCaptionChange = (e) => {
        setShowCaption(e.target.checked);
    };

    const handlePositionChange = (selection) => {
        setPosition(selection.value);
        setPositionOption(selection);
    };

    const loadImage = (files) => {
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                setSrc(reader.result);
            }
            return '';
        };
        if (files !== null) {
            reader.readAsDataURL(files[0]);
        }
    };

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [activeEditor]);

    const handleOnClick = () => {
        const payload = {altText, position, showCaption, src};
        activeEditor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, payload);
        onClose();
    };

    return (
        <div className="flex flex-col items-stretch gap-4">
            <LexicalFileInput
                label="Image Upload"
                onChange={loadImage}
                accept="image/*"
                data-test-id="image-modal-file-upload"
            />
            <LexicalTextInput
                label="Alt Text"
                placeholder="Descriptive alternative text"
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />

            <div className="flex items-center justify-between">
                <label class="Input__label">Position</label>
                <LexicalDropDown
                    variant="modal"
                    style={{flex: '2 1', minWidth: '0'}}
                    options={POSITION_OPTIONS}
                    selectedOption={positionOption}
                    onChange={(selection) => handlePositionChange(selection)}
                />
            </div>

            <div className="Input__wrapper">
                <label htmlFor="caption" class="Input__label">Show Caption</label>
                <input
                    id="caption"
                    type="checkbox"
                    checked={showCaption}
                    onChange={handleShowCaptionChange}
                />
            </div>

            <DialogActions>
                <LexicalButton
                    data-test-id="image-modal-file-upload-btn"
                    disabled={isDisabled}
                    onClick={() => handleOnClick()}
                >
                    Confirm
                </LexicalButton>
            </DialogActions>
        </div>
    );
}

export default function InlineImagePlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([LexicalInlineImageNode])) {
            throw new Error('ImagesPlugin: ImageNode not registered on editor');
        }

        return mergeRegister(
            editor.registerCommand(
                INSERT_INLINE_IMAGE_COMMAND,
                (payload) => {
                    const imageNode = $createInlineImageNode(payload);
                    $insertNodes([imageNode]);
                    if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                        $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
                    }

                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                (event) => {
                    return $onDragStart(event);
                },
                COMMAND_PRIORITY_HIGH,
            ),
            editor.registerCommand(
                DRAGOVER_COMMAND,
                (event) => {
                    return $onDragover(event);
                },
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand(
                DROP_COMMAND,
                (event) => {
                    return $onDrop(event, editor);
                },
                COMMAND_PRIORITY_HIGH,
            ),
        );
    }, [editor]);

    return null;
}

const TRANSPARENT_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

function $onDragStart(event) {
    const node = $getImageNodeInSelection();
    if (!node) {
        return false;
    }
    const dataTransfer = event.dataTransfer;
    if (!dataTransfer) {
        return false;
    }
    dataTransfer.setData('text/plain', '_');
    dataTransfer.setDragImage(img, 0, 0);
    dataTransfer.setData(
        'application/x-lexical-drag',
        JSON.stringify({
            data: {
                altText: node.__altText,
                caption: node.__caption,
                height: node.__height,
                key: node.getKey(),
                showCaption: node.__showCaption,
                src: node.__src,
                width: node.__width,
            },
            type: 'image',
        }),
    );

    return true;
}

function $onDragover(event) {
   const node = $getImageNodeInSelection();
   if (!node) {
      return false;
   }
   if (!canDropImage(event)) {
      event.preventDefault();
   }
   return true;
}

function $onDrop(event, editor) {
   const node = $getImageNodeInSelection();
   if (!node) {
      return false;
   }
   const data = getDragImageData(event);
   if (!data) {
      return false;
   }
   event.preventDefault();
   if (canDropImage(event)) {
      const range = getDragSelection(event);
      node.remove();
      const rangeSelection = $createRangeSelection();
      if (range !== null && range !== undefined) {
         rangeSelection.applyDOMRange(range);
      }
      $setSelection(rangeSelection);
      editor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, data);
   }
   return true;
}

function $getImageNodeInSelection() {
   const selection = $getSelection();
   if (!$isNodeSelection(selection)) {
      return null;
   }
   const nodes = selection.getNodes();
   const node = nodes[0];
   return $isInlineImageNode(node) ? node : null;
}

function getDragImageData(event) {
   const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
   if (!dragData) {
      return null;
   }
   const {type, data} = JSON.parse(dragData);
   if (type !== 'image') {
      return null;
   }

   return data;
}

function canDropImage(event) {
   const target = event.target;
   return !!(
      target &&
      target instanceof HTMLElement &&
      !target.closest('code, span.editor-image') &&
      target.parentElement &&
      target.parentElement.closest('div.ContentEditable__root')
   );
}

function getDragSelection(event) {
   let range;
   const target = event.target;
   const targetWindow =
      target == null
         ? null
         : target.nodeType === 9
         ? target.defaultView
         : target.ownerDocument.defaultView;
   const domSelection = getDOMSelection(targetWindow);
   if (document.caretRangeFromPoint) {
      range = document.caretRangeFromPoint(event.clientX, event.clientY);
   } else if (event.rangeParent && domSelection !== null) {
      domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
      range = domSelection.getRangeAt(0);
   } else {
      throw Error('Cannot get the selection when dragging');
   }

   return range;
}
