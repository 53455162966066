import { Fragment, useState, useEffect, useCallback } from 'react';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import dayjs from 'dayjs';
import { utils, writeFile } from 'xlsx';

import * as UiConstants from '../constants/UiConstants';
import * as EntityConstants from '../constants/EntityConstants';
import * as DataConstants from '../constants/DataConstants';
import DataUtils from '../utils/DataUtils';

import Button from './Button';
import SelectDropdown from './SelectDropdown';
import MultiSelectDropdown from './MultiSelectDropdown';
import CustomReactDatePicker from './CustomReactDatePicker';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var utc = require('dayjs/plugin/utc');

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export default function DataExcelDownloadModal({ params, open, onClose }) {

    const [organisation, setOrganisation] = useState({});

    const [selectedTypeOption, setSelectedTypeOption] = useState(UiConstants.EMPTY_OPTION);

    const [unitOptions, setUnitOptions] = useState([]);
    const [selectedUnitOption, setSelectedUnitOption] = useState(UiConstants.EMPTY_OPTION);

    const [pathwayOptions, setPathwayOptions] = useState([]);
    const [selectedPathwayOption, setSelectedPathwayOption] = useState(UiConstants.EMPTY_OPTION);

    const [freqOptions, setFreqOptions] = useState([]);
    const [selectedFreqOption, setSelectedFreqOption] = useState(UiConstants.EMPTY_OPTION);

    const [dataOptions, setDataOptions] = useState([]);
    const [selectedDataOptions, setSelectedDataOptions] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [pickerFrequency, setPickerFrequency] = useState(DataConstants.FrequencyType.NONE);
    const [startPickerLabel, setStartPickerLabel] = useState("Start Date");
    const [endPickerLabel, setEndPickerLabel] = useState("End Date");

    const getUnitOptions = (org, type) => {
        switch (type) {
            case EntityConstants.EntityType.ENTERPRISE:
                return org.enterprises.map(e => ({ value: e._id, label: e.name }));

            case EntityConstants.EntityType.PROGRAM:
                return org.programs.map(p => ({ value: p._id, label: p.name }));

            case EntityConstants.EntityType.PROJECT:
                return org.projects.map(p => ({ value: p._id, label: p.name }));

            default:
                return [];
        }
    }

    const getUnit = useCallback((org, type, unitId) => {
        let unit = null;
        let index = -1;
        switch (type) {
            case EntityConstants.EntityType.ENTERPRISE:
                index = org.enterprises.map(e => e._id).indexOf(unitId);
                if (index > -1) {
                    unit = org.enterprises[index];
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                index = org.programs.map(p => p._id).indexOf(unitId);
                if (index > -1) {
                    unit = org.programs[index];
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                index = org.projects.map(p => p._id).indexOf(unitId);
                if (index > -1) {
                    unit = org.projects[index];
                }
                break;
            default:
                break;
        }
        return unit;
    }, []);

    const getPathway = useCallback((unit, pathwayId) => {
        let pathway = null;
        if (unit) {
            const pathwayIndex = unit.pathways.map(p => p._id).indexOf(pathwayId)
            if (pathwayIndex > -1) {
                pathway = unit.pathways[pathwayIndex];
            }
        }
        return pathway;
    }, []);

    const getPathwayOptions = useCallback((org, type, unitId) => {
        let options = [];
        if (type !== "" && unitId !== "") {
            let unit = getUnit(org, type, unitId);
            if (unit) {
                return [UiConstants.EMPTY_OPTION].concat(unit.pathways.map(p => {
                    return { value: p._id, label: p.title };
                }));
            }
        }
        return options;
    }, [getUnit]);

    const getFreqOptions = useCallback((org, type, unitId, pathwayId) => {
        let options = [];
        if (type !== "" && unitId !== "" && pathwayId !== "") {
            let unit = getUnit(org, type, unitId);
            if (unit) {
                const pathway = getPathway(unit, pathwayId);
                if (pathway) {
                    const indicatorFreqs = unit.indicators ? unit.indicators.filter(ind1 => pathway.indicators.indexOf(ind1.key) > -1).map(ind2 => ind2.frequency) : [];
                    for (let i = 1; i < UiConstants.FREQUENCY_OPTIONS.length; i++) {
                        const opt = UiConstants.FREQUENCY_OPTIONS[i];
                        if (indicatorFreqs.indexOf(opt.value) > -1) {
                            options.push(opt);
                        }
                    }
                }
            }
        }
        return options.length > 0 ? [UiConstants.EMPTY_OPTION].concat(options) : [];
    }, [getUnit, getPathway]);

    const getDataOptions = useCallback((org, type, unitId, pathwayId, freq) => {
        let options = [];
        if (type !== "" && unitId !== "" && pathwayId !== "" && freq !== "") {
            let unit = getUnit(org, type, unitId);
            if (unit) {
                const pathway = getPathway(unit, pathwayId);
                if (pathway) {
                    let reqDataKeys = [];
                    pathway.indicators.forEach(indKey => {
                        const index = unit.indicators.map(ind => ind.key).indexOf(indKey);
                        if (index > -1) {
                            const indicator = unit.indicators[index];
                            if (indicator.frequency === freq) {
                                indicator.requiredData.forEach(rdk => {
                                    const rdIndex = unit.requiredData.map(r => r.key).indexOf(rdk);
                                    if (rdIndex > -1) {
                                        const reqData = unit.requiredData[rdIndex];
                                        if (reqData.requiredDataType === DataConstants.RequiredDataType.MANUAL && reqDataKeys.indexOf(rdk) === -1) {
                                            reqDataKeys.push(rdk);
                                        }
                                    }
                                });
                            }
                        }
                    });
                    reqDataKeys.forEach(rdk => {
                        const keyIndex = unit.requiredData.map(r => r.key).indexOf(rdk);
                        if (keyIndex > -1) {
                            const reqData = unit.requiredData[keyIndex];
                            options.push({ value: rdk, label: reqData.title });
                        }
                    });
                    options.sort((a, b) => {
                        if (a.title < b.title) {
                            return -1
                        } else if (a.title > b.title) {
                            return 1
                        } else {
                            return 0;
                        }
                    });
                }
            }
        }
        return options;
    }, [getUnit, getPathway]);

    /* Data Setting Functions */

    const onTypeChange = (selection) => {
        setSelectedTypeOption(selection);

        setUnitOptions(getUnitOptions(organisation, selection.value));
        setSelectedUnitOption(UiConstants.EMPTY_OPTION);

        setPathwayOptions([]);
        setSelectedPathwayOption(UiConstants.EMPTY_OPTION);

        setFreqOptions([]);
        setSelectedFreqOption(UiConstants.EMPTY_OPTION);

        setPickerFrequency(DataConstants.FrequencyType.NONE);
        setStartPickerLabel("Start Date");
        setEndPickerLabel("End Date");

        setDataOptions([]);
        setSelectedDataOptions([]);
    }

    const onUnitChange = (selection) => {
        setSelectedUnitOption(selection);

        setPathwayOptions(getPathwayOptions(organisation, selectedTypeOption.value, selection.value));
        setSelectedPathwayOption(UiConstants.EMPTY_OPTION);

        setFreqOptions([]);
        setSelectedFreqOption(UiConstants.EMPTY_OPTION);

        setPickerFrequency(DataConstants.FrequencyType.NONE);
        setStartPickerLabel("Start Date");
        setEndPickerLabel("End Date");

        setDataOptions([]);
        setSelectedDataOptions([]);
    }

    const onPathwayChange = (selection) => {
        setSelectedPathwayOption(selection);
        setFreqOptions(getFreqOptions(organisation, selectedTypeOption.value, selectedUnitOption.value, selection.value));
    }

    const setPickerLabels = (value) => {
        switch (value) {
            case DataConstants.FrequencyType.WEEKLY:
                setStartPickerLabel("Start Week");
                setEndPickerLabel("End Week");
                break;
            case DataConstants.FrequencyType.MONTHLY:
                setStartPickerLabel("Start Month");
                setEndPickerLabel("End Month");
                break;
            case DataConstants.FrequencyType.QUARTERLY:
                setStartPickerLabel("Start Quarter");
                setEndPickerLabel("End Quarter");
                break;
            case DataConstants.FrequencyType.ANNUALLY:
                setStartPickerLabel("Start Year");
                setEndPickerLabel("End Year");
                break;
            case DataConstants.FrequencyType.FINANCIAL_YEAR:
                setStartPickerLabel("Start FY");
                setEndPickerLabel("End FY");
                break;
            default:
                setStartPickerLabel("Start Date");
                setEndPickerLabel("End Date");
                break;
        }
    }

    const onFrequencyChange = (selection) => {
        setSelectedFreqOption(selection);
        let dataOpts = getDataOptions(organisation, selectedTypeOption.value, selectedUnitOption.value, selectedPathwayOption.value, selection.value);
        setDataOptions(dataOpts);
        setSelectedDataOptions(dataOpts);

        setPickerFrequency(selection.value);
        setPickerLabels(selection.value);
    }

    const onRequiredDataChange = (selection) => {
        setSelectedDataOptions(selection);
    }

    const onStartDateChange = (value)=> {
        setStartDate(value);
    }

    const onEndDateChange = (value)=> {
        setEndDate(value);
    }

    /* Spreadsheet Generating Functions */

    const getFinYearStart = (date) => {
        let year = date.year();
        if (date.month() < 6) {
            year = year - 1;
        }
        date = date.date(1).month(6).year(year);
        return date;
}

    const createIntervalHeaders = (freq, startDate, endDate) => {
        let headers = [];
        if (freq !== "" && startDate != null && endDate != null) {
            //let startDt = dayjs(startDate);
            //let endDt = dayjs(endDate);
            let startDt = DataUtils.getUtcDate(startDate);
            let endDt = DataUtils.getUtcDate(endDate);
            let rangeType = "";
            let current = null;
            let end = null;
            switch (freq) {
                case DataConstants.FrequencyType.WEEKLY:
                    startDt = startDt.startOf("week");
                    endDt = endDt.startOf("week");
                    rangeType = "week";
                    break;
                case DataConstants.FrequencyType.MONTHLY:
                    startDt = startDt.startOf("month");
                    endDt = endDt.startOf("month");
                    rangeType = "month";
                    break;
                case DataConstants.FrequencyType.QUARTERLY:
                    startDt = startDt.startOf("quarter");
                    endDt = endDt.startOf("quarter");
                    rangeType = "quarter";
                    break;
                case DataConstants.FrequencyType.FINANCIAL_YEAR:
                    startDt = getFinYearStart(startDt);
                    endDt = getFinYearStart(endDt);
                    rangeType = "year";
                    break;
                case DataConstants.FrequencyType.ANNUALLY:
                    startDt = startDt.startOf("year");
                    endDt = endDt.startOf("year");
                    rangeType = "year";
                    break;
                default:
                    break;
            }
            if (startDt <= endDt) {
                current = startDt;
                end = endDt
            } else {
                current = endDt;
                end = startDt
            }
            if (rangeType !== "") {
                headers.push("Data");
                while (current <= end) {
                    switch (freq) {
                        case DataConstants.FrequencyType.WEEKLY:
                            const weekend = current.endOf("week");
                            headers.push(`Week ${current.week()} ${current.year()} (${current.format("D MMM")} - ${weekend.format("D MMM")})`);
                            break;
                        case DataConstants.FrequencyType.MONTHLY:
                            headers.push(current.format("MMM YYYY"));
                            break;
                        case DataConstants.FrequencyType.QUARTERLY:
                            headers.push(`Q${current.quarter()} ${current.year()}`);
                            break;
                        case DataConstants.FrequencyType.FINANCIAL_YEAR:
                            const year = current.year();
                            headers.push(`FY ${year}-${year + 1}`);
                            break;
                        case DataConstants.FrequencyType.ANNUALLY:
                            headers.push(current.format("YYYY"));
                            break;
                        default:
                            break;
                    }
                    current = current.add(1, rangeType);
                }
            }
        }
        return headers;
    }

    const createTemplateJSON = (headers, dataOptions) => {
        let json = [];
        for (let i = 0; i < dataOptions.length; i++) {
            let row = {
                Data: dataOptions[i].label
            };
            for (let h = 0; h < headers.length; h++) {
                if (headers[h] !== "Data") {
                    row[headers[h]] = "";
                }
            }
            json.push(row);
        }
        return json;
    }

    const getFileName = (entityTitle, freq, headers) => {
        let titleCleaned = entityTitle.replace(/[^a-zA-Z0-9]/g, "");
        titleCleaned = titleCleaned.replaceAll(" ", "_");
        let name = `${titleCleaned}_${freq}_Data`;
        if (headers.length > 1) {
            name = `${name}-${headers[1].replaceAll(" ", "_")}-${headers[headers.length - 1].replaceAll(" ", "_")}`;
        }
        return `${name}.xlsx`;
    }

    const exportWorkbook = (freq, template, options, filename) => {
        const worksheet = utils.json_to_sheet(template, options);
        const workbook = utils.book_new();
        const worksheetName = `${freq} Data`;
        utils.book_append_sheet(workbook, worksheet, worksheetName);
        writeFile(workbook, filename, { compression: true });
    }

    /* Footer Button Interactions */

    const onCancelClick = () => {
        onClose();
    };

    const onDownloadClick = () => {
        if (selectedDataOptions.length === 0) {
            alert("You need to have some datasets selected to create a spreadsheet");
            return;
        }
        if (selectedFreqOption.value === "" || startDate === null || endDate === null) {
            alert("You need to set a data entry interval, start date & end date to create a spreadsheet");
            return;
        }
        const headers = createIntervalHeaders(selectedFreqOption.value, startDate, endDate);
        const template = createTemplateJSON(headers, selectedDataOptions);
        const filename = getFileName(selectedUnitOption.label, selectedFreqOption.value, headers);
        exportWorkbook(selectedFreqOption.value, template, { header: headers }, filename);
        onClose();
    }

    /* useEffect Functions */

    useEffect(() => {
        if (open) {
            const org = params.organisation ? params.organisation : { enterprises: [], programs: [], projects: [] };

            const type = params.type ? params.type : "";
            const typeIndex = UiConstants.UNIT_TYPE_OPTIONS.map(o => o.value).indexOf(type);
            const typeOpt = typeIndex > -1 ? UiConstants.UNIT_TYPE_OPTIONS[typeIndex] : UiConstants.EMPTY_OPTION;

            const unit = params.unit ? params.unit : "";
            const unitOpts = getUnitOptions(org, type);
            const unitIndex = unitOpts.map(u => u.value).indexOf(unit);
            const unitOpt = unitIndex > -1 ? unitOpts[unitIndex] : UiConstants.EMPTY_OPTION;

            const pathway = params.pathway ? params.pathway : "";
            const pathwayOpts = getPathwayOptions(org, type, unit);
            const pathIndex = pathwayOpts.map(p => p.value).indexOf(pathway);
            const pathOpt = pathIndex > -1 ? pathwayOpts[pathIndex] : UiConstants.EMPTY_OPTION;

            const frequency = params.frequency ? params.frequency : "";
            const freqOpts = getFreqOptions(org, type, unit, pathway); 
            const freqIndex = freqOpts.map(f => f.value).indexOf(frequency);
            const freqOpt = freqIndex > -1 ? freqOpts[freqIndex] : UiConstants.EMPTY_OPTION;

            const dataOpts = getDataOptions(org, type, unit, pathway, frequency);

            setOrganisation(org);

            setSelectedTypeOption(typeOpt);

            setUnitOptions(unitOpts);
            setSelectedUnitOption(unitOpt);

            setPathwayOptions(pathwayOpts);
            setSelectedPathwayOption(pathOpt);

            setFreqOptions(freqOpts);
            setSelectedFreqOption(freqOpt);

            setPickerFrequency(freqOpt.value);
            setPickerLabels(freqOpt.value);
    
            setDataOptions(dataOpts);
            setSelectedDataOptions(dataOpts);

            setStartDate(null);
            setEndDate(null);
        }
    }, [params, open, getDataOptions, getFreqOptions, getPathwayOptions]);

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </TransitionChild>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <div className="flex flex-col items-stretch gap-8">
                                    <DialogTitle
                                        as="h4"
                                        className="font-vg-medium text-3.5xl text-black leading-110"
                                    >
                                        Download Excel Import Template
                                    </DialogTitle>
                                    <div className="grid grid-cols-2 gap-x-6 gap-y-3">
                                        <div className="flex flex-col items-stretch gap-3 col-span-2 mb-4">
                                            <h6 className="font-vg-book text-base text-black">
                                                This will create an Excel template that will allow you to enter data offline and upload it to Seedkit.
                                            </h6>
                                            <h6 className="font-vg-book text-base text-black">
                                                Select the reporting unit, pathway and range of intervals to obtain an template containing rows of required data to be entered:
                                            </h6>
                                        </div>
                                        <SelectDropdown
                                            label="Select Type"
                                            options={UiConstants.UNIT_TYPE_OPTIONS}
                                            selectedOption={selectedTypeOption || UiConstants.EMPTY_OPTION}
                                            onChange={onTypeChange}
                                        />
                                        <SelectDropdown
                                            label={selectedTypeOption.label ? `Select ${selectedTypeOption.label}` : "Select Reporting Unit"}
                                            options={unitOptions ? unitOptions : []}
                                            selectedOption={selectedUnitOption || UiConstants.EMPTY_OPTION}
                                            onChange={onUnitChange}
                                        />
                                        <SelectDropdown
                                            label="Select Impact Pathway"
                                            options={pathwayOptions ? pathwayOptions : []}
                                            selectedOption={selectedPathwayOption || UiConstants.EMPTY_OPTION}
                                            onChange={onPathwayChange}
                                        />
                                        <SelectDropdown
                                            label="Data Entry Interval"
                                            options={freqOptions}
                                            selectedOption={selectedFreqOption || UiConstants.EMPTY_OPTION}
                                            onChange={onFrequencyChange}
                                        />
                                        <CustomReactDatePicker
                                            label={startPickerLabel}
                                            frequency={pickerFrequency}
                                            value={startDate}
                                            onChange={onStartDateChange}
                                        />
                                        <CustomReactDatePicker
                                            label={endPickerLabel}
                                            frequency={pickerFrequency}
                                            value={endDate}
                                            onChange={onEndDateChange}
                                        />
                                        <div className="col-span-2">
                                            <MultiSelectDropdown 
                                                label={selectedFreqOption.value === "" ? "Required Data" : `${selectedFreqOption.label} Required Data`} 
                                                options={dataOptions}
                                                help="You can select multiple options"
                                                selectedOptions={selectedDataOptions}
                                                onChange={onRequiredDataChange}
                                                template="{COUNT} Datasets Selected"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-3">
                                        <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                                        <Button variant="solid" size="large" label="Download" className="w-full" onClick={onDownloadClick}/>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}