import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';
import * as MatomoConstants from '../../constants/MatomoConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

export default function NewMemberUserPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;
  
    const [submitCss, setSubmitCss] = useState('');
    const [submitStatus, setSubmitStatus] = useState('');
    const [submitError, setSubmitError] = useState('');

    const params = useParams();
    const navigate = useNavigate();

    const authToken = params.token ? params.token : "";
    const accessCode = params.code ? params.code : "";

	const validationSchema = Yup.object().shape({
		password: Yup.string().required('Please enter a password'),
		confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords do not match!")
  	});
  	const formOptions = { 
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: "",
			confirmPassword: "",
		} 
  	};
  	const { 
		control, 
		handleSubmit,
		watch
  	} = useForm(formOptions);

    function handleSetPassword(data) {
        if (authToken !== '' && accessCode !== '') {
            setSubmitCss('hidden');
            setSubmitStatus('Processing...');
            AuthService.verifyMemberSignUp(authToken, accessCode, data.password)
            .then((_) => {
                navigate('/kit/home');
            })
            .catch(error => {
                setSubmitCss('');
                setSubmitStatus('');
                console.log('error', error);
                setSubmitError(error.response && error.response.data ? error.response.data.message || error.message : error.message);
            });
        } else {
            setSubmitError('Unable to set as validation codes were not provided');
        }
    }

	useEffect(() => {
		const subscription = watch((data, { name, type }) => {
			setSubmitError('');
	  	});
	  	return () => subscription.unsubscribe();
 	}, [watch, setSubmitError]);

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageTitle.SET_MEMBER_PASSWORD
        });
        // eslint-disable-next-line
    }, []);

    return(
        <div className={containerClass}>
            <Header title="Set Password"/>
            <div className="container mx-auto px-3 text-black pb-10">
                <div className="w-full max-w-screen-sm mx-auto px-3">
                    <h2 className="font-vg-medium text-3xl text-left my-10">
                        Set your password to start using your account
                    </h2>
                    <form className="relative w-full flex flex-col items-stretch gap-2" onSubmit={handleSubmit(handleSetPassword)}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput
                                    type="password" 
                                    variant={formState.errors.password ? "error" : "default"}
                                    label="Enter Password"
                                    help={formState.errors.password?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput
                                    type="password" 
                                    variant={formState.errors.confirmPassword ? "error" : "default"}
                                    label="Confirm Password"
                                    help={formState.errors.confirmPassword?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />

                        <div className="flex items-center mt-2">
                            <Button type="submit" variant="solid" size="large" className={submitCss} label="SET PASSWORD"/>
                            <p className="font-vg-medium text-base text-black h-9" aria-live="polite">{submitStatus}</p>
                        </div>

                        <p className="font-vg-bold text-base text-red my-5 min-h-8 leading-8" aria-live="polite">{submitError}</p>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>
    );
}