import axios from 'axios';
import Cookies from 'js-cookie';

import * as GeneralConstants from '../constants/GeneralConstants';

let AuthService = {};

AuthService.BASIC_LOGIN = "basic";
AuthService.TWO_FA_LOGIN = "twofa"

AuthService.login = (username, password, method, loadData) => {
    let url = process.env.REACT_APP_API_URL + '/auth/login?verbose=true&method=' + method;
    if (loadData) {
        url = url + "&load=full";
    }
    return axios.post(url, { username, password }, { withCredentials: true });
};

AuthService.isLoggedIn = () => {
    return (Cookies.get(GeneralConstants.LOGIN_COOKIE_KEY) === "true");
};

AuthService.logout = () => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/logout', {},
        { withCredentials: true }
    );
};

AuthService.startSignUp = (details) => {
    const body = {
        abn: details.abn,
        abnState: details.abnState,
        organisationName: details.organisationName,
        prefTradingName: details.prefTradingName ? details.prefTradingName : "",
        email: details.email,
        password: details.password,
        firstName: details.firstName ? details.firstName : "",
        lastName: details.lastName ? details.lastName : "",
        jobTitle: details.jobTitle ? details.jobTitle : "",
        captcha: details.captcha ? details.captcha : "",
        trialAccount: details.trialAccount ? details.trialAccount : false,
        agreeToTerms: details.agreeToTerms ? details.agreeToTerms : false,
        agreeToComms: details.agreeToComms ? details.agreeToComms : false
    }
    return axios.post(process.env.REACT_APP_API_URL + '/auth/signup/start', 
        body, 
        { withCredentials: true }
    );
};

AuthService.verifySignUp = (authToken, accessCode) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/signup/verify', 
        { authToken, accessCode }, 
        { withCredentials: true }
    );
};

AuthService.verifyLogin = (authToken, accessCode) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/login/verify', 
        { authToken, accessCode }, 
        { withCredentials: true }
    );
};

AuthService.startForgotPassword = (email) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/password/forgot/start', 
        { email }, 
        { withCredentials: true }
    );
}

AuthService.verifyForgotPassword = (authToken, accessCode) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/password/forgot/verify', 
        { authToken, accessCode }, 
        { withCredentials: true }
    );
};

AuthService.changePassword = (newPassword) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/password/change', 
        { newPassword }, 
        { withCredentials: true }
    );
};

AuthService.verifyMemberSignUp = (authToken, accessCode, password) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/member/verify', 
        { authToken, accessCode, password }, 
        { withCredentials: true }
    );
}

AuthService.verifyEmailChange = (authToken, accessCode, password) => {
    return axios.post(process.env.REACT_APP_API_URL + '/auth/change/verify', 
        { authToken, accessCode, password }, 
        { withCredentials: true }
    );
}

AuthService.resendEmailChangeEmail = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/auth/change/verify', 
        { withCredentials: true }
    );
}

export default AuthService;
